import {computed, ComputedRef, reactive, Ref, ref, UnwrapNestedRefs, watch} from "vue";
import {LocationQueryRaw, useRoute} from "vue-router";
import {debounce} from "lodash";
import {PaginationParams, Cohort, Institution, Tenant} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";

export interface PaginationConfig {
  initialColumns: ColumnsConfig;
  initialSort?: SortOption[];
  initialItemsPerPage?: number;
}

export interface ColumnConfig {
  title: string;
  modelKey: string;
  visible: boolean;
  filter: string | string [];
  filterText?: string;
}

export interface ColumnsConfig {
  [key: string]: ColumnConfig;
}

export type Filters = Record<string, unknown>;

export interface SortOption {
  key: string;
  order: "asc" | "desc";
}

export interface Header {
  [key: string]: string;

  title: string;
  key: string;
}

interface PaginationItem {
  type: "button" | "text";
  value: number | string;
}

export interface FooterData {
  firstPage: number;
  lastPage: number;
  nextPage: number | null;
  prevPage: number | null;
  currentPage: number;
  totalPages: number | null;
  totalDocs: number | null;
  limit: number;
  pageText: string;
  pageSpread: PaginationItem[] | [];
}

interface PaginationData {
  page: number;
  limit: number;
  totalPages: number;
  totalDocs: number | null;
  nextPage: number | null;
  prevPage: number | null;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}

function computeHeaders(columns: ColumnsConfig): Header[] {
  return Object.keys(columns)
    .filter((key) => columns[key].visible)
    .map((key): Header => ({
      title: columns[key].title,
      key: columns[key].modelKey
    }));
}

export function usePagination(fetchData: () => Promise<void>, config: PaginationConfig) {
  const {
    initialColumns,
    initialSort = [{
      key: "createdAt",
      order: "asc"
    }],
    initialItemsPerPage = 20
  } = config;

  const columns: UnwrapNestedRefs<ColumnsConfig> = reactive(initialColumns);
  const route = useRoute();
  const headers: ComputedRef<Header[]> = computed(() => computeHeaders(columns));

  // Common filters
  const createdAtDateFilter = ref<Date[]>([]);
  const selectedTenant = ref<Tenant>();
  const selectedInstitution = ref<Institution>();
  const selectedCohort = ref<Cohort>();

  // Fetch new data when column state changes
  watch(columns, async () => {
    await fetchData();
  });
  function watchDateFilter(filter: Ref<Date[]>, column: ColumnConfig) {
    watch(() => filter.value, (dates: Date[] | undefined) => {
      const parsedDates = parseDates(dates);
      updateDateRangeFilter(column, parsedDates);
    });
  }

  watch(() => selectedTenant.value, (newTenant: Tenant | undefined) => {
    updateTenantInstitutionCohortColumnFilter(columns.tenant, newTenant);
  });

  watch(() => selectedInstitution.value, (newInstitution: Institution | undefined) => {
    updateTenantInstitutionCohortColumnFilter(columns.institution, newInstitution);
  });

  watch(() => selectedCohort.value, (newCohort: Cohort | undefined) => {
    updateTenantInstitutionCohortColumnFilter(columns.cohort, newCohort);
  });

  /**
   * The current page.
   */
  const page = ref(
    route.query.page ? parseInt(route.query.page as string) : 1
  );

  /**
   * The number of items per page.
   */
  const perPage = ref(route.query.itemsPerPage ? parseInt(route.query.itemsPerPage as string) : initialItemsPerPage);

  const sort: Ref<SortOption[]> = ref(initialSort);
  const searchText = ref("");

  /**
   * Returns how many filters are active
   */
  const activeFilterCount = computed(() => {
    let count = 0;

    for (const key in columns) {
      const column = columns[key];

      if (Array.isArray(column.filter) && column.filter.length > 0) {
        count++;
      }

      if (column.filter && typeof column.filter === "string") {
        count++;
      }
    }

    return count;
  });

  /**
   * Returns the current filters.
   */
  function resetFilter() {
    for (const key in columns) {
      const column = columns[key];
      column.filter = "";
      column.filterText = "";
    }
  }

  /**
   * Sets the current filters to be used in a URL.
   * @param filters The current filters.
   */
  function createUrlFilterParameters(filters: PaginationParams): LocationQueryRaw {
    const newQueryParams: LocationQueryRaw = {};

    Object.entries(filters).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        newQueryParams[key] = value.toString();
      }
    });

    return newQueryParams;
  }

  /**
   * Checks if there are active tenants filter,
   * and if there are fetches and assigns the data to the models for the dropdowns
   */
  async function setupTenantFilters(
    tenantId: string | undefined,
    institutionId: string | undefined,
    cohortId: string | undefined
  ) {
    const [
      tenantResult,
      institutionResult,
      cohortResult
    ] = await Promise.all([
      tenantId ? yapi.admin.tenant.get(columns.tenant.filter as string) : null,
      institutionId ? yapi.admin.institution.get(columns.institution.filter as string) : null,
      cohortId ? yapi.admin.cohort.get(columns.cohort.filter as string) : null,
    ]);

    if (tenantResult)
      selectedTenant.value = tenantResult.data;

    if (institutionResult)
      selectedInstitution.value = institutionResult.data;

    if (cohortResult)
      selectedCohort.value = cohortResult.data;
  }

  /**
   * Parses the given dates and adds one day to the end date.
   * @param dates The dates to parse.
   */
  function parseDates(dates: Date[] | undefined) {
    return dates?.map((date) => new Date(date)) || [];
  }

  /**
   * Updates the date range filter.
   * @param column The column to update.
   * @param dates The dates to filter by.
   */
  function updateDateRangeFilter(column: ColumnConfig, dates: Date[] | undefined) {
    column.filter = [];
    column.filterText = "";
    if (dates) {
      const [filterStart, filterEnd] = dates;

      column.filter.push(filterStart.toISOString());
      column.filterText = filterStart.toDateString();

      column.filter.push(filterEnd.toISOString());
      column.filterText += ` to ${filterEnd.toDateString()}`;
    }
  }

  function updateTenantInstitutionCohortColumnFilter(
    column: ColumnConfig, newValue: Tenant | Institution | Cohort | undefined
  ) {
    column.filter = "";
    column.filterText = "";

    if (newValue && newValue._id) {
      column.filter = newValue._id;
      column.filterText = newValue.title;
    }
  }

  /**
   * Event handler for toggling the visibility of a column.
   * @param key The key of the column to toggle.
   */
  function toggleColumnVisible(key: string) {
    columns[key].visible = !columns[key].visible;
  }

  /**
   * Event handler for updating the number of items per page.
   * @param numPerPage The number of items per page to show.
   */
  async function onUpdatePerPage(numPerPage: number) {
    perPage.value = numPerPage;
    await fetchData();
  }

  /**
   * Event handler for sorting the table.
   * @param sortOptions
   */
  async function onSort(sortOptions: SortOption[]) {
    if (sortOptions.length === 0) {
      // v-data-table passes empty array when sorting desc.
      sort.value = [{
        key: sort.value[0].key,
        order: sort.value[0].order === "asc" ? "desc" : "asc"
      }];
    } else {
      sort.value = sortOptions;
    }
    await fetchData();
  }

  /**
   * Event handler for going to the next page.
   */
  const onNext = async () => flipPage(1);

  /**
   * Event handler for going to the previous page.
   */
  const onPrev = async () => flipPage(-1);

  /**
   * Flips the page by the given number.
   * @param num
   */
  const flipPage = async (num: number) => {
    page.value = page.value + num;
    await fetchData();
  };

  const onUpdateSearch = (text: string) => debounce(async () => {
    searchText.value = text;
    await fetchData();
  }, 1000);

  /**
   * Handles changing the page when selecting a page number in the pagination footer.
   * @param pageNumber
   */
  async function onPageChange(pageNumber: number | string) {
    const numericPage = typeof pageNumber === "string" ? parseInt(pageNumber) : pageNumber;
    page.value = numericPage;
    await fetchData();
  }

  return {
    columns,
    headers,
    page,
    perPage,
    sort,
    activeFilterCount,
    createdAtDateFilter,
    selectedTenant,
    selectedInstitution,
    selectedCohort,
    setupTenantFilters,
    resetFilter,
    createUrlFilterParameters,
    toggleColumnVisible,
    watchDateFilter,
    updateDateRangeFilter,
    onUpdatePerPage,
    onSort,
    onNext,
    onPrev,
    onPageChange,
    onUpdateSearch,
    setupFooterData
  };
}

/**
   * Setup of dataset for use in pagination footer.
   * @param paginationData
   */
export function setupFooterData(paginationData: PaginationData) {
  const rangeUpperLimit = paginationData.page * paginationData.limit;
  const rangeLowerLimit = rangeUpperLimit - paginationData.limit + 1;

  const footerData: FooterData = {
    firstPage: 1,
    limit: paginationData.limit,
    lastPage: paginationData.totalPages,
    nextPage: paginationData.nextPage,
    prevPage: paginationData.prevPage,
    currentPage: paginationData.page,
    totalPages: paginationData.totalPages || null,
    totalDocs: paginationData.totalDocs || null,
    pageText: "",
    pageSpread: [],
  };

  if (paginationData.hasNextPage || paginationData.hasPrevPage) {
    // TODO: Improve
    const ofTotal = paginationData.totalDocs ? " of " + paginationData.totalDocs : " of many";
    const upperLimit = paginationData.totalDocs && rangeUpperLimit < paginationData.totalDocs
      ? rangeUpperLimit : paginationData.totalDocs ? paginationData.totalDocs : rangeUpperLimit;

    footerData.pageText = "Showing " + rangeLowerLimit + " - " + upperLimit + ofTotal;
  } else {
    footerData.pageText = "Showing " + rangeLowerLimit + " - " + rangeUpperLimit;
  }

  footerData.pageSpread = generatePaginationSpread(paginationData.page, paginationData.totalPages);

  return footerData;
}

/**
  * Creates an array containing the page numbers to be visible, and the ellipses for hidden pages.
  * @param currentPage
  * @param lastPage
  */
function generatePaginationSpread(currentPage: number, lastPage: number): PaginationItem[] {
  const pagination: PaginationItem[] = [];

  if (lastPage === undefined) {
    return [];
  } else {
    // Handle left side of the current page
    if (currentPage - 1 >= 2) {
      pagination.push({type: "button", value: 1});
      pagination.push({type: "text", value: "..."});
      pagination.push({type: "button", value: currentPage - 1});
    } else {
      for (let i = 1; i < currentPage; i++) {
        pagination.push({type: "button", value: i});
      }
    }

    // Add the current page
    pagination.push({type: "button", value: currentPage});

    // Handle right side of the current page
    if (lastPage - currentPage >= 2) {
      pagination.push({type: "button", value: currentPage + 1});
      pagination.push({type: "text", value: "..."});
      pagination.push({type: "button", value: lastPage});
    } else {
      for (let i = currentPage + 1; i <= lastPage; i++) {
        pagination.push({type: "button", value: i});
      }
    }

    return pagination;
  }
}

/**
 * Data factory to create a default FooterData object.
 */
export function footerDataFactory() {
  const footerData: FooterData = {
    firstPage: 1,
    lastPage: 1,
    nextPage: null,
    prevPage: null,
    currentPage: 1,
    totalPages: null,
    totalDocs: null,
    limit: 20,
    pageText: "",
    pageSpread: []
  };
  return footerData;
}
