<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-buildings
    </template>
    <template #header>
      {{ tenant?.title }}
    </template>

    <template #breadcrumbs>
      <v-breadcrumbs
        density="compact"
        class="pl-0 text-caption"
      >
        <v-breadcrumbs-item :to="{ name: 'tenants' }" class="font-weight-bold" color="secondary">
          {{ $t("components.TenantDetailPage.breadcrumb") }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider/>
        <v-breadcrumbs-item color="secondary">
          {{ tenant?.title }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>

    <template #context-menu>
      <v-menu bottom transition="scale-transition">
        <template v-slot:activator="{ props }">
          <v-btn-primary
            v-bind="props"
            prepend-icon="fa fa-bars"
            data-pw="menuButton"
          >
            {{ $t('buttons.menu') }}
          </v-btn-primary>
        </template>
        <v-list min-width="250px">
          <v-list-item
            @click="showCreateInstitution = true"
            data-pw="createInstitutionButton"
          >
            {{ $t("components.TenantDetailPage.menu.create_institution") }}
          </v-list-item>
          <v-list-item @click="showDisableEnableTenant = true">
            <span v-show="tenant?.disabled" style="color:green;">
              {{ $t('components.TenantDetailPage.menu.enable') }}
            </span>
            <span v-show="!tenant?.disabled" style="color:red;">
              {{ $t('components.TenantDetailPage.menu.disable') }}
            </span>
          </v-list-item>
          <v-list-item @click="showDeleteTenant = true">
            <span style="color:red;">{{ $t("components.TenantDetailPage.menu.delete") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-container v-if="tenant" class="py-4">
      <v-alert
        v-if="tenant?.disabled"
        type="warning"
        density="compact"
        class="my-4"
      >
        {{ $t("components.TenantDetailPage.content.this_tenant_disabled") }}
      </v-alert>

      <v-row>
        <v-col cols="12">
          <h2>{{ $t("components.TenantDetailPage.content.tenant_detail") }}</h2>
        </v-col>
        <v-col cols="12">
          <tenant-details :tenant="tenant"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <managers-table v-if="isAdminOrRoot" @revoked="fetchTenant" :managers="managers" :entity-type="Entity.Tenant"/>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showCreateInstitution" :persistent="true" max-width="500">
      <create-tenant-institution-cohort
        :entity-title="$t('components.CreateCards.CreateTenantInstitutionCohort.titleCreateInstitution')"
        :tenant="tenant"
        :entity-type="Entity.Institution"
        :allowed="store.hasAbility(Entity.Institution, Action.create) || isAdminOrRoot"
        data-pw="createInstitutionCard"
        @close="showCreateInstitution = false"
      />
    </v-dialog>

    <v-dialog v-model="showDisableEnableTenant" :persistent="true" max-width="500">
      <disable-enable-tenant-dialog
        v-if="tenant" @close="onDisableEnableDialog"
        :disabled="tenant ? tenant.disabled : false"
      />
    </v-dialog>

    <v-dialog
      v-model="showDisableEnableTenant"
      :persistent="true"
      max-width="500"
    >
      <disable-enable-tenant-dialog
        v-if="tenant"
        @close="onDisableEnableDialog"
        :disabled="tenant ? tenant.disabled : false"
      />
    </v-dialog>

    <v-dialog
      v-model="showDeleteTenant"
      :persistent="true"
      max-width="500"
    >
      <delete-tenant
        v-if="tenant"
        @close="showDeleteTenant = false"
        :title="$t('components.TenantDetailPage.delete_title')"
        :confirm-string="tenant ? tenant.title : ''"
        :message="$t('components.TenantDetailPage.warning') + tenant.title"
        :label="$t('components.TenantDetailPage.instruction')"
      />
    </v-dialog>
  </authenticated-layout>
</template>

<script setup lang="ts">
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import {onMounted, ref} from "vue";
import {Action, Entity, isYapiError, Role, Tenant, User, YapiError} from "@YenzaCT/sdk";
import {useRoute} from "vue-router";
import yapi from "@/lib/yapi";
import TenantDetails from "@/pages/TenantDetailPage/components/TenantDetails.vue";
import DisableEnableTenantDialog from "@/pages/TenantDetailPage/components/DisableEnableTenantDialog.vue";
import {useGlobalStore} from "@/store";
import DeleteTenant from "@/pages/TenantDetailPage/components/DeleteTenant.vue";
import ManagersTable from "@/components/ManagersTable.vue";
import CreateTenantInstitutionCohort from "@/components/CreateCards/CreateTenantInstitutionCohortCard.vue";

const store = useGlobalStore();
const route = useRoute();

const tenant = ref<Tenant>();
const managers = ref<User[]>([]);
const showCreateInstitution = ref(false);
const showDisableEnableTenant = ref(false);
const showDeleteTenant = ref(false);
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

async function fetchTenant() {
  try {
    store.networkBusy = true;
    const tenantId = route.params.id as string;
    const [r1, r2] = await Promise.all([
      yapi.admin.tenant.get(tenantId),
      yapi.admin.managers.list(Entity.Tenant, tenantId),
    ]);
    tenant.value = r1.data;
    managers.value = r2.data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchTenant();
});

async function onDisableEnableDialog() {
  showDisableEnableTenant.value = false;
  await fetchTenant();
}
</script>
<style>
.selectable {
  cursor: pointer;
}
.selectable:hover {
  background-color: #f5f5f5;

}
</style>
