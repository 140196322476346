<template>
  <v-card
    :title="t('components.AccessCodeTablePage.create_voucher')"
    :loading="store.networkBusy">
    <v-card-text>
      <v-form>
        <h4 data-pw="accountTypeHeader">{{ $t('components.CreateVoucherCard.account_type') }}</h4>
        <choose-voucher-account-type v-model="voucherAccountType" data-pw="accountTypeList"/>
        <div class="my-4">
          <h4>{{ $t("components.CreateVoucherCard.quantity") }}</h4>
          <v-slider
            v-model="quantity"
            data-pw="quantitySlider"
            class="align-center"
            density="compact"
            :max="999"
            :min="1"
            :step="1"
            hide-details
          >
            <template v-slot:prepend>
              <v-text-field
                v-model="quantity"
                hide-details
                single-line
                data-pw="quantitySliderInput"
                variant="outlined"
                density="compact"
                type="number"
                style="width: 90px"
              ></v-text-field>
            </template>
          </v-slider>
        </div>
        <div class="mt-4">
          <h4>
            {{ $t('components.CreateVoucherCard.tenanted') }}
          </h4>
          <v-switch :label="$t('components.CreateVoucherCard.belongs_to_tenant')" v-model="tenanted"/>
        </div>
        <v-sheet v-if="tenanted" color="grey-lighten-3" rounded class="pa-4">
          <h3>{{ $t('components.CreateVoucherCard.tenant_settings') }}</h3>
          <span
            class="text-caption">{{ $t('components.CreateVoucherCard.tenant_settings_text_caption') }}</span>
          <tenant-search-dropdown
            v-model="selectedTenant"
            class="mt-4"
          />
          <tenant-institution-single-select-autocomplete
            v-model="selectedInstitution"
            :tenant="selectedTenant"
            class="mb-4"
          />
          <cohort-search-dropdown
            v-model="selectedCohort"
            :institution="selectedInstitution"
            :tenant="selectedTenant"
          />
        </v-sheet>
        <h4>
          {{ $t('components.CreateVoucherCard.research') }}
        </h4>
        <v-switch :label="$t('components.CreateVoucherCard.used_for_research')" v-model="research"/>
        <v-sheet v-if="research" color="grey-lighten-3" rounded class="pa-4">
          <h3>
            {{
              $t('components.CreateVoucherCard.research_assessments')
            }}
          </h3>
          <span class="text-caption">
            {{
              $t('components.CreateVoucherCard.research_settings_text_caption')
            }}
          </span>
          <v-checkbox v-for="(assessment, index) in assessmentTypeOptions"
                      :key="index"
                      :label="assessment.title"
                      :value="assessment.value"
                      density="compact"
                      hide-details
                      @click="() => toggleResearchAssessment(assessment.value)"/>
        </v-sheet>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        @click="$emit('close')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn-secondary>
      <v-btn-primary
        :disabled="!canConfirm || store.networkBusy"
        data-pw="confirmButton"
        @click="createAccessCode"
      >
        {{ $t('buttons.confirm') }}
      </v-btn-primary>
    </v-card-actions>
  </v-card>
  <v-snackbar v-model="store.snackbarVisible">
    {{ store.snackbarMessage }}
  </v-snackbar>
</template>

<script setup lang="ts">
import yapi from "@/lib/yapi";
import {
  AccessCode,
  CoreAssessmentType,
  isYapiError,
  YapiError,
  Institution,
  Cohort,
  Tenant,
  AccountType
} from "@YenzaCT/sdk";
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import CohortSearchDropdown from "@/components/FormFields/CohortSearchDropdown.vue";
import TenantInstitutionSingleSelectAutocomplete
  from "@/components/FormFields/InstitutionSearchDropdown.vue";
import ChooseVoucherAccountType from "@/components/Voucher/ChooseVoucherAccountType.vue";
const store = useGlobalStore();
const {t} = useI18n();
const tenanted = ref(false);
const selectedCohort = ref<Cohort | undefined>();
const selectedInstitution = ref<Institution | undefined>();
const selectedTenant = ref<Tenant | undefined>();

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const assessmentTypeOptions = ref(Object.values(CoreAssessmentType)
  .map((slug) => ({
    title: t(`components.CreateVoucherCard.assessments.${slug}`),
    value: slug
  })));

const selectedAssessmentsDefault: Record<string, unknown> = {};
Object.values(CoreAssessmentType)
  .forEach((slug) => {
    selectedAssessmentsDefault[slug] = false;
  });

const researchSelected = ref(selectedAssessmentsDefault);

const voucherAccountType = ref<AccountType>(AccountType.Starter);

const quantity = ref(1);
const research = ref(false);

function toggleResearchAssessment(assessment: string | CoreAssessmentType) {
  researchSelected.value[assessment] = !researchSelected.value[assessment];
}

const canConfirm = computed(() => {
  const tenantSettingsValid = tenanted.value ? !!(tenanted.value && selectedCohort.value && selectedCohort.value._id) : true;

  const atLeastOneResearchSelected = Object.values(researchSelected.value)
    .some((v) => v);
  const researchSettingsValid = research.value ? atLeastOneResearchSelected : true;

  return tenantSettingsValid && researchSettingsValid;
});

const createAccessCode = async () => {
  store.networkBusy = true;
  try {
    const accessCode = {
      accountType: voucherAccountType.value,
      researchAssessments: Object.keys(researchSelected.value)
        .filter((key) => researchSelected.value[key]),
    } as AccessCode;

    if (tenanted.value && selectedCohort.value && selectedCohort.value._id) {
      accessCode.cohort = selectedCohort.value._id;
    }

    await yapi.admin.accessCode.create(accessCode, quantity.value);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      store.handleNetworkLoadingAndSnackbar(t("components.CreateVoucherCard.snackbarErrorMessage"));
    } else {
      throw e;
    }
  } finally {
    store.handleNetworkLoadingAndSnackbar(`${t("components.CreateVoucherCard.snackbarCompletedMessage") + quantity.value}`);
    emit("close");
  }
};
</script>
