import {createApp} from "vue";
import {createPinia} from "pinia";
import "vuetify/styles";
import {createI18n} from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import enZA from "@/locales/en_ZA";
import af from "@/locales/af";
import vuetify from "./plugins/vuetify";
import {getCookie} from "typescript-cookie";
import initSentry from "./lib/sentry";
import {useGlobalStore} from "@/store";
import {loadAppConfiguration} from "@/lib/appConfiguration";
import {AppConfiguration} from "@YenzaCT/sdk";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

loadAppConfiguration().then((appConfiguration: AppConfiguration) => {
  const pinia = createPinia();

  const locale = getCookie("locale") || appConfiguration.locales.default;

  const i18n = createI18n<false>({
    globalInjection: true,
    locale: locale,
    legacy: false,
    fallbackLocale: "en_ZA",
    messages: {
      "en_ZA": enZA,
      "af": af
    }
  });

  const app = createApp(App);

  initSentry(app, router);

  app.use(pinia);
  const store = useGlobalStore();

  store.appConfiguration = appConfiguration;

  app.use(router);
  app.use(i18n);
  app.use(vuetify(appConfiguration.themeLight || {}, appConfiguration.themeDark || {}));

  app.component("VueDatePicker", VueDatePicker);
  app.component("VueTelInput", VueTelInput);

  app.mount("#app");
}).catch((e) => {
  document.writeln("Failed to load app configuration. Please try again.");
  // eslint-disable-next-line no-console
  console.error(e);
});
