{
  "buttons": {
    "edit": "Redigeer",
    "delete": "Skrap",
    "create": "Skep",
    "close": "Sluit",
    "cancel": "Kanselleer",
    "confirm": "Bevestig",
    "export": "Uitvoer",
    "apply": "Toepassing",
    "menu": "Kieslys",
    "reset": "Terugstel",
    "filter": "Filter",
    "columns": "Kolomme",
    "manage": "Bestuur",
    "view": "Bekyk",
    "clear_filters": "Maak filters skoon",
    "upload": "Oplaai",
    "custom": "Pasgemaakte",
    "previous": "Vorige",
    "accept": "Aanvaar",
    "deny": "Weier",
    "open_filters": "Maak filters oop",
    "export_raw_data": "Voer rou data uit",
    "next": "Volgende",
    "export_to_csv": "Voer uit na CSV",
    "export_to_excel": "Uitvoer na Excel",
    "disable": "Deaktiveer",
    "enable": "Aktiveer",
    "close_filters": "Maak filters toe",
    "reset_filters": "Stel filters terug",
    "submit": "Dien in",
    "account_settings": "Rekening instellings",
    "download_reports": "Laai verslae af",
    "save": "Stoor",
    "profile": "Profiel"
  },
  "products": {
    "FREE_TO_STARTER": "Vry om te begin",
    "FREE_TO_POWER": "Vry om te pleeg",
    "STARTER_TO_POWER": "Begin om te pleeg",
    "ASSESSMENT_ONLY_TO_POWER": "Assessering slegs om te pleeg",
    "ASSESSMENT_ONLY_TO_STARTER": "Assessering net om te begin",
    "ASSESSMENT_ONLY_BEHAVIOUR_TO_POWER": "Assessering Slegs gedrag om te pleeg",
    "ASSESSMENT_ONLY_BEHAVIOUR_TO_STARTER": "Assessering slegs gedrag om te begin",
    "ASSESSMENT_ONLY_BEHAVIOUR_PLUS_TO_POWER": "Slegs assessering gedrag plus om te pleeg",
    "ASSESSMENT_ONLY_BEHAVIOUR_PLUS_TO_STARTER": "Assessering Slegs Gedrag Plus om te Begin"
  },
  "coupon": {
    "id": "ID",
    "code": "Kode",
    "title": "Titel",
    "type": "Tiepe",
    "enabled": "Geaktiveer",
    "research": "Navorsing",
    "cohort": "Kohort",
    "max_uses": "Maksimum gebruike",
    "usage_count": "Gebruik tel",
    "amount": "Bedrag",
    "discount": "Afslag (%)",
    "expires_at": "Verval",
    "products": "Produkte",
    "types": {
      "full": "Volledige",
      "percent": "Persent",
      "fixed": "Vaste"
    },
    "used": "Gebruik",
    "percent_amount_label": "Afslag (%)",
    "create_coupon": "Skep koepon",
    "title_required": "Titel word vereis",
    "code_required": "Kode word vereis",
    "delete_coupon": "Skrap koepon",
    "delete_message": "Is jy seker jy wil hierdie koepon uitvee?"
  },
  "accessCode": {
    "id": "ID",
    "code": "Koopbewys kode",
    "user": "Gebruiker",
    "account_type": "Tipe rekening",
    "research": "Navorsing",
    "used": "Gebruik",
    "used_at": "Gebruik by",
    "tenant": "Huurder",
    "institution": "Instelling",
    "cohort": "Kohort",
    "actions": "Aksies",
    "research_assessments": "Navorsingsassesserings"
  },
  "accountTypes": {
    "free": "Gratis",
    "assessmentOnly": "Slegs assessering: Hele persoon",
    "assessmentOnlyBehaviour": "Slegs assessering: Gedragsgedrag",
    "assessmentOnlyBehaviourPlus": "Slegs assessering: Gedrags-Plus",
    "starter": "Begin",
    "power": "Pleeg",
    "powerPlus": "Pleeg+"
  },
  "couponTypes": {
    "percent": "Persent",
    "full": "Volledige",
    "fixed": "Vaste"
  },
  "roles": {
    "tenant_manager": "Huurder Bestuurder",
    "admin": "Admin",
    "user": "Gebruiker",
    "manager": "Bestuurder",
    "deleted": "Geskrap",
    "root": "Wortel"
  },
  "assessments": {
    "big_5": {
      "title": "Persoonlikheidseienskappe"
    },
    "holland_code": {
      "title": "Werker tipe",
      "R": "Bouer",
      "I": "Denker",
      "A": "Skepper",
      "S": "Helper",
      "E": "Beïnvloeder",
      "C": "Organiseerder"
    },
    "ipip": {
      "title": "Ipip"
    },
    "interests": {
      "title": "Belange"
    },
    "maze": {
      "title": "Doolhof-assessering",
      "rating": {
        "veryHigh": "Uitstekend",
        "high": "Goeie",
        "medium": "Gemiddelde",
        "low": "Swak",
        "veryLow": "Baie arm"
      },
      "body": "U het 'n uitstekende telling op die Maze Assessment getoon. Hierdie assessering dui op iemand se vermoë om deur visuele inligting te redeneer en/of probleme op te los.",
      "speedHeading": "Spoed",
      "description": "U het hierdie assessering binne 2 minute voltooi. Dit is 'n goeie tyd om hierdie assessering te voltooi.",
      "totalSwapsTitle": "Totale aantal ruilings",
      "totalSwapsDescription": "Aantal kere het jy die blokke geskuif. Dit meet hoeveel aksies u geneem het om die regte antwoord te kry.",
      "averageSwapsTitle": "Gemiddelde snelheid (ruil / minuut)",
      "averageSwapsDescription": "Aantal ruilings per minuut wat u probleemoplossingstyl aandui.",
      "info": "Moet inligting byvoeg"
    },
    "assessment_not_done": "Assessering nie voltooi nie",
    "entrepreneurial_intent": {
      "title": "Entrepreneuriese voorneme"
    },
    "learning_styles": {
      "title": "Leerstyle",
      "high": "Hoë",
      "moderate": "Matige",
      "low": "Lae",
      "balanced": "Gebalanseerde"
    },
    "managing_emotion": {
      "title": "Die bestuur van emosie",
      "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    "understanding_emotion": {
      "title": "Verstaan emosie",
      "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    "perceiving_emotion": {
      "title": "Waarneming van emosie",
      "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    "emotional_intelligence": {
      "title": "Emosionele intelligensie"
    },
    "rock_paper_scissors": {
      "title": "Assessering van rotspapier skêr",
      "rating": {
        "veryHigh": "Uitstekend",
        "high": "Goeie",
        "medium": "Gemiddelde",
        "low": "Swak",
        "veryLow": "Baie arm"
      },
      "body": "U het 'n nie te armoedige telling op die Rock Paper Scissors Assessment getoon nie. Hierdie assessering dui op iemand se vermoë om deur visuele inligting te redeneer en/of probleme op te los.",
      "description": "Jy het hierdie assessering binne 2 minute voltooi",
      "info": "Moet inligting byvoeg"
    },
    "cognitive_ability": {
      "title": "kognitiewe vermoë",
      "info": "OM UIT TE VIND"
    }
  },
  "common": {
    "segment": "Segment",
    "search_email": "Soek e-pos",
    "no_data_message": "Geen data beskikbaar nie",
    "fetching_message": "Haal data...",
    "download_status": "Jou uitvoer laai af.",
    "details": "Besonderhede",
    "confirm_title": "Bevestig",
    "confirm_text": "Is jy seker?",
    "entities": {
      "User": "Gebruiker",
      "AccessCode": "Koopbewys",
      "Coupon": "Koepon",
      "Cohort": "Kohort",
      "Institution": "Instansie",
      "Tenant": "Huurder"
    },
    "permissions": {
      "read": "Lees",
      "create": "Skep",
      "edit": "Redigeer",
      "delete": "Skrap"
    },
    "type_confirm_message": "Tik asseblief 'BEVESTIG' hieronder om voort te gaan.",
    "title_required": "Titel word vereis",
    "search_title": "Soek titel",
    "created_at": "Geskep by",
    "edit": "Redigeer",
    "yes": "Ja",
    "no": "Nee",
    "all": "Alle",
    "coming_soon_label": "Volledige uiteensetting kom binnekort.",
    "title": "Titel",
    "_id": "ID",
    "curriculum": "Kurrikulum",
    "insights": "Insigte",
    "none": "Geen",
    "loading": "Laai tans...",
    "assessments": "Assesserings",
    "researchAssessments": "Navorsingsassesserings",
    "results": "Resultate",
    "progress": "Vordering",
    "user_permissions": "Gebruiker Toestemmings",
    "per_page": "Per bladsy",
    "vs": "teenoor",
    "overview": "Oorsig",
    "search": "Soek",
    "filters": "Filters",
    "columns": "Kolomme",
    "country": "Land",
    "slug": "Slak",
    "low": "Lae",
    "medium": "Medium",
    "high": "Hoë",
    "period": "Tydperk",
    "no_data": "Geen data nie",
    "breakdown_by": "Uiteensetting deur",
    "tenant": "Huurder",
    "institution": "Instelling",
    "cohort": "Kohort",
    "total": "Totale",
    "preview": "Voorskou",
    "excel": "Excel",
    "csv": "CSV",
    "card_title": "Titel",
    "select_option": "Kies",
    "countries": {
      "ZA": "Suid-Afrika",
      "KE": "Kenia",
      "ET": "Ethiopië",
      "AE": "VAE"
    },
    "no_permission": "Jy het nie toestemming om 'n huurder te skep nie."
  },
  "components": {
    "ManagersTable": {
      "title": "Bestuurders",
      "no_manager_message": "Geen bestuurders beskikbaar nie",
      "revoke_permissions": "Herroep permisies",
      "revoke_warning_message": "Is u seker dat u hierdie bestuurders se toestemmings wil herroep?",
      "delete": "Skrap",
      "email": "E-pos",
      "first_name": "Voornaam",
      "last_name": "Van",
      "actions": "Aksies"
    },
    "AccessCodeTablePage": {
      "title": "Koopbewys kodes",
      "create_voucher": "Skep koopbewys",
      "search": "Soek kodes",
      "no_data_message": "Geen data beskikbaar nie"
    },
    "ProfileCard": {
      "email": "E-pos",
      "phone": "Foon",
      "account_type": "Tipe rekening",
      "role": "Rol",
      "tenant": "Huurder",
      "institution": "Instelling",
      "cohort": "Kohort"
    },
    "EditInstitution": {
      "update_message": "Instelling suksesvol opgedateer"
    },
    "DeleteInstitution": {
      "title": "Skrap instelling",
      "instruction": "Tik asseblief in:",
      "warning_message": "U is op die punt om uit te vee:",
      "success_message": "Instelling suksesvol geskrap"
    },
    "DeleteCohort": {
      "title": "Verwyder kohort",
      "delete_message": "Kohort suksesvol uitgevee"
    },
    "CreateCohortDialog": {
      "title:": "Skep kohort"
    },
    "CreateCohortDropdown": {
      "title": "Skep kohort"
    },
    "ReportPage": {
      "title": "Data-insigte",
      "card_titles": {
        "usage": "Gebruik",
        "distribution": "Verspreiding",
        "user_results": "Gebruiker resultate",
        "dashboards": "Dashboards"
      },
      "card_subtitles": {
        "usage": "Kies 'n verslag uit die lys hieronder om gebruikers se gebruik van die toepassing te ondersoek."
      },
      "labels": {
        "coming_soon": "Kom binnekort"
      },
      "reports": {
        "usage": {
          "voucher_usage": "Koopbewys gebruik",
          "worker_type_completion": "Werker tipe voltooiing",
          "personality_type_completion": "Persoonlikheid tipe voltooiing",
          "subject_selection_completion": "Vakkeuse Voltooiing"
        },
        "users": {
          "user_subject_choices": "Gebruiker vak keuses",
          "user_worker_types": "Tipes gebruikerswerkers",
          "user_personality_types": "Tipes gebruikerspersoonlikheid"
        },
        "distributions": {
          "subject_choice_distribution": "Verspreiding van vakkeuses",
          "worker_type_distribution": "Werker tipe verspreiding",
          "personality_type_distribution": "Persoonlikheid tipe verspreiding"
        },
        "dashboards": {
          "group_analysis": "Groep analise"
        }
      }
    },
    "ReportsDetailPage": {
      "insights": "Insigte",
      "data_insights": "Data-insigte",
      "no_result": "Geen resultaat nie",
      "no_subject_selected": "Geen vak gekies nie",
      "reports": {
        "voucher-usage": "Koopbewys gebruik",
        "worker-type-completion": "Werker tipe voltooiing",
        "worker-type-distribution": "Werker tipe verspreiding",
        "personality-type-completion": "Persoonlikheid tipe voltooiing",
        "subject-selection-completion": "Vakkeuse Voltooiing",
        "user-subject-choices": "Gebruiker vak keuses",
        "user-worker-types": "Tipes gebruikerswerkers",
        "user-personality-types": "Tipes gebruikerspersoonlikheid",
        "learning-styles-distribution": "Verspreiding van leerstyle",
        "subject-choice-distribution": "Verspreiding van vakkeuses",
        "labels": {
          "select_at_least": "Kies asseblief ten minste 'n",
          "select_a": "Kies asseblief 'n",
          "run_report": "om die verslag uit te voer.",
          "select_an_option": "Kies asseblief 'n",
          "voucher_usage_for": "Koopbewysgebruik vir"
        },
        "access_codes": {
          "usage_report": {
            "headers": {
              "tenant": "Huurder",
              "institution": "Instelling",
              "cohort": "Kohort",
              "percentage_used": "%",
              "used": "Gebruik",
              "not_used": "Nie gebruik nie",
              "total": "Totale"
            },
            "completion_report": {
              "headers": {
                "institution": "Instelling",
                "cohort": "Kohort",
                "percentage": "%",
                "complete": "Voltooi",
                "incomplete": "Onvolledige",
                "total": "Totale"
              }
            },
            "user_report": {
              "filter": {
                "cohort": "Kohort",
                "primary_type": "Primêre tipe",
                "secondary_type": "Sekondêre tipe",
                "tertiary_type": "Tersiêre tipe"
              },
              "no_results": "Geen resultaat nie"
            }
          }
        },
        "big_5_completion_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder om die verslag uit te voer."
        },
        "holland_code_completion_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder om die verslag uit te voer."
        },
        "access_code_completion_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder om die verslag uit te voer."
        },
        "subject_choice_distribution_report": {
          "select_report": "Kies asseblief al die bogenoemde filters om die verslag uit te voer."
        },
        "subject_choice_selection_completion_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder en Kurrikulum om die verslag uit te voer.",
          "select_report_curriculum": "Kies asseblief ten minste 'n kurrikulum om die verslag uit te voer."
        },
        "users_big_5_types_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder en 'n Instelling om die verslag uit te voer.",
          "select_an_institution": "Kies asseblief 'n instelling."
        },
        "users_holland_codes_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder en 'n Instelling om die verslag uit te voer.",
          "select_an_institution": "Kies asseblief 'n instelling.",
          "no_results": "Geen resultate nie",
          "filter": {
            "cohort": "Kohort",
            "primary_type": "Primêre tipe",
            "secondary_type": "Sekondêre tipe",
            "tertiary_type": "Tersiêre tipe"
          }
        },
        "worker_type_distribution_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder en 'n Instelling om die verslag uit te voer.",
          "select_an_institution": "Kies asseblief ten minste 'n instelling om die verslag uit te voer."
        },
        "users_subject_choice_report": {
          "select_report": "Kies asseblief ten minste 'n Huurder en 'n Instelling om die verslag uit te voer.",
          "select_an_institution": "Kies asseblief ten minste 'n instelling om die verslag uit te voer.",
          "filter": {
            "cohort": "Kohort"
          }
        },
        "completion_report": {
          "headers": {
            "institution": "Instelling",
            "cohort": "Kohort",
            "percentage": "Persentasie",
            "complete": "Voltooi",
            "incomplete": "Onvolledige",
            "total": "Totale"
          }
        },
        "cohort-dashboard": "Kohort Dashboard",
        "group-dashboard": "Groep Dashboard",
        "personality-traits-distribution": "Persoonlikheidseienskappe Verspreiding"
      },
      "settings": {
        "report_period": "Verslag tydperk",
        "breakdown_by": "Uiteensetting deur",
        "filter_by": "Filter Deur"
      },
      "filters": {
        "tenant": "Huurder",
        "tenant_instruction": "Soek asseblief vir 'n huurder.",
        "institution": "Instelling",
        "cohort": "Kohort",
        "cohort_instruction": "Kies asseblief 'n huurder."
      },
      "actions": {
        "run_report": "Begin verslag",
        "export": "Uitvoer"
      },
      "messages": {
        "run_report": "Klik asseblief Run Report om die verslag te genereer."
      },
      "holland_code_headers": {
        "institution": "Instelling",
        "cohort": "Kohort",
        "voucher_code": "Koopbewys kode",
        "email": "E-pos",
        "phone": "Foon",
        "full_name": "Volle naam",
        "primary": "Primêre",
        "secondary": "Sekondêre",
        "tertiary": "Tersiêre",
        "tenant": "Huurder"
      },
      "big_5_headers": {
        "tenant": "Huurder",
        "institution": "Instelling",
        "cohort": "Kohort",
        "voucher_code": "Koopbewys kode",
        "email": "E-pos",
        "phone": "Foon",
        "full_name": "Volle naam",
        "open_mindedness": "Oopkop",
        "conscientiousness": "Pligsgetrouheid",
        "extraversion": "Ekstraversie",
        "agreeableness": "Aangenaamheid",
        "negative_emotionality": "Neurotisisme"
      },
      "subject_choice_distribution": {
        "alert": "Geen data gevind vir die geselekteerde filters nie.",
        "card_titles": {
          "math_vs_math_literacy": "Wiskunde vs Wiskunde Geletterdheid",
          "first_language": "Eerste taal",
          "second_language": "Tweede Taal",
          "group_1": "Groep 1",
          "group_2": "Groep 2",
          "elective_distribution": "Elektiewe verspreiding"
        }
      },
      "worker_type_distribution": {
        "card_titles": {
          "primary_worker_type": "Primêre werker tipe",
          "secondary_worker_type": "Sekondêre werkertipe",
          "tertiary_worker_type": "Tipe tersiêre werker"
        },
        "alert": "Geen beskikbare data nie."
      },
      "nsCertificateHeaders": {
        "subjects": {
          "national_senior_certificate": {
            "first_language": "Eerste taal",
            "second_language": "Tweede Taal",
            "mathematics": "Wiskunde",
            "elective_1": "Keusevak 1",
            "elective_2": "Keusevak 2",
            "elective_3": "Keusevak 3"
          }
        }
      },
      "cambridgeHeaders": {
        "subjects": {
          "cambridge": {
            "group_1": "Groep 1",
            "group_2": "Groep 2",
            "group_3": "Groep 3",
            "group_4": "Groep 4",
            "group_5": "Groep 5",
            "undefined": "Ongedefinieerde"
          }
        }
      }
    },
    "SubjectSelectionCompletionReport": {
      "title": "Vakkeuse Voltooiing",
      "headers": {
        "tenant": "Huurder",
        "institution": "Instelling",
        "cohort": "Kohort",
        "percentage_complete": "Persentasie voltooi",
        "complete": "Voltooi",
        "incomplete": "Onvolledige",
        "total": "Totale"
      }
    },
    "HollandCodeCompletionReport": {
      "title": "Werker tipe voltooiing",
      "headers": {
        "tenant": "Huurder",
        "institution": "Instelling",
        "cohort": "Kohort",
        "percentage_complete": "Persentasie voltooi",
        "complete": "Voltooi",
        "incomplete": "Onvolledige",
        "total": "Totale",
        "cohort_filter_label": "Filter Kohort"
      }
    },
    "AccessCodeUsageReport": {
      "title": "Koopbewys gebruik",
      "voucher_usage": "Koopbewys gebruik",
      "tenant": "Huurder",
      "institution": "Instelling",
      "cohort": "Kohort",
      "percentage_used": "Persentasie gebruik",
      "used": "Gebruik",
      "not_used": "Nie gebruik nie",
      "total": "Totale"
    },
    "Big5CompletionReport": {
      "title": "Persoonlikheidseienskappe voltooiing",
      "headers": {
        "tenant": "Huurder",
        "institution": "Instelling",
        "cohort": "Kohort",
        "percentage_complete": "Persentasie voltooi",
        "complete": "Voltooi",
        "incomplete": "Onvolledige",
        "total": "Totale"
      }
    },
    "UsersSubjectChoicesReport": {
      "no_subject_selected": "Geen vak gekies nie"
    },
    "BasicAuthCard": {
      "title": "Rekening besonderhede",
      "account_created": "Rekening geskep",
      "last_login": "Laaste aanmelding",
      "last_reset_request": "Laaste herstelversoek",
      "account_locked": "Rekening gesluit",
      "locked_until": "Gesluit tot",
      "failed_login_attempts": "Mislukte aanmeldpogings",
      "account_enabled": "Rekening geaktiveer",
      "download_reports": "Laai verslae af"
    },
    "AssessmentsDetail": {
      "instructions": "Gebruik die onderstaande aftreklyste om die resultate van die gebruikers se assessering te ondersoek."
    },
    "UserDetailPage": {
      "back_breadcrumb": "Gebruikers",
      "account_disabled": "Hierdie rekening is gedeaktiveer",
      "tabs": {
        "assessments": "Assesserings",
        "choices": "Keuses"
      },
      "menu": {
        "enable": "Aktiveer",
        "disable": "Deaktiveer",
        "change_email": "Verander e-posadres",
        "change_phone_number": "Verander telefoonnommer",
        "change_account_type": "Verander rekeningtipe",
        "change_role": "Verander rol",
        "reset_password": "Stel wagwoord terug",
        "download_cv": "Laai CV af",
        "download_subject_report": "Laai vakverslag af",
        "download_assessment_report": "Laai assesseringsverslag af",
        "reset_assessments": "Stel assesserings terug"
      },
      "coming_soon_label": "Kom binnekort",
      "user_detail_section_heading": {
        "profile": "Profiel",
        "assessment_reports": "Assesseringsverslae",
        "choices": "Keuses",
        "permissions": "Toestemmings",
        "authentication": "Waarmerking"
      },
      "user_detail_profile": {
        "title": "Gebruiker profiel"
      },
      "user_detail_manager_permissions": {
        "title": "Bestuurder Toestemmings",
        "warning": "Slegs bestuurders kan toestemmings hê",
        "update_role_button": "Dateer gebruikersrol op"
      },
      "manager_instructions": "Kies 'n huurder, instelling of kohort om toestemmings by hierdie bestuurder te voeg",
      "user_authentication": "Gebruikerverifikasie",
      "view_report": "Bekyk verslag",
      "hide_report": "Versteek verslag",
      "user_detail_choices": {
        "curriculum": "Kurrikulum",
        "core_subjects": "Kernvakke",
        "electives": "Keusevakke",
        "none_selected": "Geen geselekteer nie"
      },
      "user_detail_section": {
        "email": "E-pos",
        "phone": "Foon",
        "account_type": "Rekening tipe",
        "role": "Rol",
        "details": "Besonderhede",
        "edit": "Redigeer",
        "reset_progress": "Terugstel",
        "download_reports": "Laai verslae af",
        "download": "Aflaai"
      }
    },
    "ChangeEmailAddressDialog": {
      "title": "Verander e-pos",
      "new_email": "Nuwe e-pos",
      "confirm_email": "Bevestig e-pos",
      "email_invalid": "E-pos is ongeldig",
      "warning": "Die verandering van 'n gebruiker se e-pos kan onbedoelde gevolge hê. Maak asseblief seker dat jy weet wat jy doen."
    },
    "ChangePhoneDialog": {
      "title": "Verander telefoonnommer",
      "new_phone_number": "Nuwe telefoonnommer",
      "confirm_phone_number": "Bevestig telefoonnommer",
      "phone_number_required": "Telefoonnommer word benodig",
      "phone_number_invalid": "Telefoonnommer is ongeldig",
      "phone_number_mismatch": "Telefoonnommers stem nie ooreen nie",
      "international_phone_format": "Telefoonnommer moet met + begin en internasionale skakelkode insluit."
    },
    "ChangeUserRole": {
      "title": "Verander gebruikersrol",
      "subtitle": "Kies asseblief 'n rol:"
    },
    "DisableEnableDialog": {
      "disable_user": "Skakel gebruiker uit",
      "enable_user": "Aktiveer gebruiker",
      "user_disabled": "Gebruiker gedeaktiveer",
      "user_enabled": "Gebruiker geaktiveer",
      "title": "Deaktiveer / aktiveer gebruiker",
      "warning": "Jy is op die punt om die gebruiker te {action}."
    },
    "DownloadSubjectChoiceDialog": {
      "title": "Laai vakkeuseverslag af",
      "download_instruction": "Klik op bevestig om die gebruikersverslag af te laai.",
      "no_curriculum_selected": "Die gebruiker het nie hul vakke gekies nie."
    },
    "MethodsCard": {
      "title": "Gebruiker besonderhede",
      "snapplify_user": "Snapplify gebruiker",
      "stdbank_user": "Standard Bank Gebruiker",
      "azuread_user": "Azure AD User",
      "email": "E-pos",
      "phone": "Foon",
      "oidc_user": "OIDC-gebruiker",
      "oAuth_provider": "OAuth-verskaffers",
      "no_oAuth_provider": "Geen oAuth-verskaffers gebruik nie"
    },
    "PermissionsList": {
      "alert_message": "Die gebruiker het geen toegewysde toestemmings nie."
    },
    "ResetAssessmentDialog": {
      "title": "Kies die assessering wat u vir die gebruiker wil terugstel.",
      "label": "Tipe lopende rekening:",
      "warning_label": "WAARSKUWING!",
      "action_label": "Hierdie aksie kan nie ongedaan gemaak word nie",
      "snackbar_message": "Die aangevraagde assesserings is herstel.",
      "danger_zone": "Gevaarsone",
      "assessments": {
        "holland_code": "Werker Tipe",
        "big_5": "Persoonlikheidseienskappe",
        "learning_styles": "Leerstyle",
        "managing_emotion": "Bestuur van emosies",
        "understanding_emotion": "Verstaan emosies",
        "perceiving_emotion": "Waarneming van emosies",
        "rock_paper_scissors": "Rots papier skêr",
        "maze": "Doolhof",
        "steu_b": "Steu B",
        "stem_b": "Stam B",
        "entrepreneur-holland-code": "Entrepreneur Holland Kode",
        "ipip": "IPIP",
        "interests": "Belange",
        "entrepreneurial_intent": "Entrepreneuriese voorneme",
        "value_scales": "Waarde skale"
      }
    },
    "ResetPasswordDialog": {
      "title": "Stel wagwoord terug",
      "info": "Wagwoorde moet tussen 8 en 256 karakters bevat en 'n kombinasie van minstens drie van die volgende gebruik: HOOFLETTERS, kleinletters, syfers en simbole.",
      "new_password_label": "Nuwe wagwoord",
      "confirm_password_label": "Bevestig wagwoord",
      "invalid_password": "Voldoen nie aan die vereistes nie",
      "passwords_do_not_match": "Wagwoorde stem nie ooreen nie",
      "password_required": "Wagwoord word benodig",
      "successfully_reset": "Wagwoord suksesvol teruggestel",
      "warning": "Gebruiker nie gelaai nie"
    },
    "CVCompletion": {
      "title": "CV Voltooiing",
      "name": "Naam",
      "birthday": "Verjaardag",
      "phone": "Foon",
      "about": "Oor",
      "experience": "Ervaring",
      "education": "Onderwys",
      "achievements": "Prestasies",
      "skills": "Vaardighede",
      "interests": "Belange"
    },
    "SubjectChoicesPanel": {
      "title": "Vakkeuses",
      "curriculum": "Kurrikulum keuse",
      "compulsory": "Verpligte vakkeuses",
      "elective": "Elektiewe vakkeuses",
      "not_selected": "Nie gekies nie"
    },
    "FavouritedCareersPanel": {
      "expansion_panel_title": "Gunsteling loopbane",
      "no_careers_available": "Geen gunsteling loopbane beskikbaar nie"
    },
    "ChangeAccountTypeDialog": {
      "title": "Verander gebruikersrekeningtipe",
      "label": "Tipe lopende rekening:"
    },
    "CognitiveAbilityReport": {
      "very_low": "Baie laag",
      "low": "Lae",
      "medium": "Medium",
      "high": "Hoë",
      "very high": "Baie hoog"
    },
    "CohortDetailPage": {
      "title": "Kohort Detail",
      "details": "Besonderhede",
      "cohorts": "Kohorte",
      "cohort": "Kohort",
      "menu": {
        "reset_assessments": "Stel assesserings terug"
      },
      "proceed_instruction": "Tik asseblief die kohorttitel om voort te gaan:",
      "delete_message": "U is op die punt om uit te vee:"
    },
    "ReportFilterOptions": {
      "run_report": "Begin verslag",
      "filter_by": "Filter Deur",
      "breakdown_by": "Uiteensetting deur"
    },
    "ChoicesCompletion": {
      "favourited_careers": "Gunsteling loopbane",
      "subject_choices": "Vakkeuses"
    },
    "ResetCohortAssessments": {
      "title": "Stel assessering terug",
      "instruction": "Kies die assesserings wat u vir kohort wil terugstel:",
      "label": {
        "select_all": "Kies Alles"
      }
    },
    "InstitutionDetailPage": {
      "title": "Instelling",
      "manage_curriculum": "Bestuur kurrikulum",
      "subtitle": "Instansie besonderhede",
      "create_cohort": "Skep kohort",
      "d6": "D6",
      "d6_details": "D6 Besonderhede",
      "join_requests": "Sluit aan by versoeke"
    },
    "D6Connection": {
      "title": "Verbinding",
      "school_id": "Skool ID",
      "connect": "Koppel",
      "disconnect": "Ontkoppel"
    },
    "JoinRequests": {
      "title": "Sluit aan by versoeke",
      "disabled": "Aansluitingsversoeke is gedeaktiveer",
      "requestTitle": "Iemand wil aansluit",
      "noRequests": "Geen hangende aansoeke nie",
      "email": "E-pos",
      "firstName": "Voornaam",
      "lastName": "Van",
      "phone": "Telefoonnommer",
      "accept_dialog_title": "Aanvaar aansluitingsversoek",
      "deny_dialog_title": "Weier aansluitingsversoek",
      "deny_dialog_message": "Is u seker dat u hierdie versoek wil weier?"
    },
    "D6Details": {
      "title": "Besonderhede",
      "school_name": "Skool se naam",
      "admin_email_address": "Admin E-pos",
      "telephone_number": "Telefoonnommer",
      "telephone_calling_code": "Telefoon kode",
      "website": "Webwerf",
      "physical_address": "Fisiese adres",
      "country": "Land",
      "province": "Provinsie",
      "error_message": "Fout om skool by D6 te haal"
    },
    "EditInstitutionCurriculum": {
      "card_title": "Bestuur kurrikulums",
      "curriculum": "Kurrikulum",
      "save_alert": "Ongeredde veranderinge",
      "title_select_available_curriculums": "1. Kies beskikbare kurrikulums",
      "instruction_select_curriculums_enabled": "Kies die kurrikulums wat u wil aktiveer.",
      "title_select_curriculum": "2. Kies kurrikulum",
      "instruction_select_curriculum_below": "Kies 'n kurrikulum hieronder.",
      "instruction_select_curriculum_above": "Kies 'n beskikbare kurrikulum hierbo om te begin.",
      "title_subject_groups": "Vakgroepe",
      "instruction_click_subject_group": "Klik op 'n groep om vakke te kies.",
      "buttons": {
        "copy": "Kopieer",
        "select_none": "Kies Geen",
        "select_all": "Kies Alles"
      },
      "title_subject": "Onderwerp",
      "instruction_select_subject": "Kies vakke vir:",
      "subtitle": "Instelling detail",
      "update_message": "Kurrikulum suksesvol opgedateer"
    },
    "InstitutionCurricula": {
      "title": "Kurrikulum-instellings",
      "info": "Geen pasgemaakte kurrikulum is opgestel nie. Die verstekkurrikulum sal beskikbaar wees.",
      "alert_no_subjects": "Geen vakke is aangepas nie, die standaard sal beskikbaar wees."
    },
    "InstitutionTablePage": {
      "title": "Instellings",
      "create_institution": "Skep instelling"
    },
    "LoginPage": {
      "title": "Aanteken",
      "welcome_back_message": "Welkom terug! Voer asseblief jou besonderhede in."
    },
    "OTPDialog": {
      "title": "OTP benodig",
      "instruction": "Voor jou OTP in asseblief.",
      "invalid_otp": "Ongeldige kode"
    },
    "LoginForm": {
      "password": "Wagwoord",
      "contact_required": "E-pos of telefoon word benodig",
      "password_required": "Wagwoord word benodig",
      "password_capital_letter_required": "Wagwoord moet ten minste een hoofletter bevat",
      "password_lowercase_letter_required": "Wagwoord moet ten minste een kleinletter bevat",
      "password_required_least_6": "Wagwoord moet ten minste 6 karakters bevat",
      "password_one_number_required": "Wagwoord moet ten minste een nommer bevat",
      "valid_email_warning": "E-pos moet geldig wees",
      "contact_email": "E-pos",
      "contact_phone": "Foon",
      "login": "Aanteken",
      "phone_required": "Telefoonnommer word benodig",
      "forgotten_password": "Wagwoord vergeet?",
      "click_here_to_reset": "Klik hier om terug te stel",
      "continue_with": "Gaan voort met",
      "continue_with_snapplify": "Gaan voort met Snapplify",
      "continue_with_fundi": "Gaan voort met Fundi"
    },
    "ForgotPasswordForm": {
      "back_to_login": "Terug na Aanmelding",
      "contact_email": "E-pos",
      "contact_phone": "Foon",
      "enter_your_phone_number": "Voer asseblief jou telefoonnommer in",
      "email_warning": "E-pos moet geldig wees",
      "phone_warning": "Sel moet geldig wees",
      "account_not_found": "Rekening nie gevind nie:",
      "request_reset": "Versoek herstel",
      "reset_your_password": "Stel jou wagwoord terug",
      "send_OTP": "Stuur OTP",
      "too_recent": "U het te onlangs 'n terugstelling versoek.",
      "you_will_receive_otp": "Jy sal 'n Eenmalige Pen (OTP) ontvang om jou wagwoord terug te stel."
    },
    "RecoveryEmailSent": {
      "title": "Herstel skakel gestuur",
      "password_reset": "Gaan asseblief jou e-pos na vir 'n skakel om jou wagwoord terug te stel."
    },
    "TenantTablePage": {
      "title": "Huurders",
      "table_headers": {
        "_id": "ID",
        "title": "Titel",
        "disabled": "Gedeaktiveer",
        "created_at": "Geskep by"
      }
    },
    "CreateTenantDialog": {
      "title": "Skep huurder",
      "created_message": "Huurder geskep"
    },
    "TenantDetailPage": {
      "breadcrumb": "Huurders",
      "menu": {
        "create_institution": "Skep instelling",
        "enable": "Aktiveer",
        "disable": "Deaktiveer",
        "delete": "Verwyder huurder"
      },
      "delete_title": "Verwyder huurder",
      "warning": "U is op die punt om uit te vee:",
      "instruction": "Tik asseblief in:",
      "content": {
        "this_tenant_disabled": "Hierdie huurder is gestremd.",
        "tenant_detail": "Huurder detail",
        "create_edit_title": "Skep of redigeer huurderbestuurder vir Yenza"
      },
      "dialog": {
        "create_institution": "Skep instelling"
      }
    },
    "DisableEnableTenantDialog": {
      "disable_tenant": "Skakel huurder uit",
      "enable_tenant": "Aktiveer huurder",
      "tenant_disabled": "Huurder gestremd",
      "tenant_enabled": "Huurder geaktiveer",
      "title": "Deaktiveer / aktiveer huurder",
      "warning": "Jy is op die punt om die huurder te {action}.",
      "info": "Tik asseblief BEVESTIG hieronder om voort te gaan."
    },
    "UserTablePage": {
      "title": "Gebruikers",
      "firstName": "Voornaam",
      "lastName": "Van",
      "table_headers": {
        "tenant": "Huurder",
        "institution": "Instelling",
        "cohort": "Kohort",
        "access_code": "Toegang tot kode",
        "first_name": "Voornaam",
        "last_name": "Van",
        "email": "E-pos",
        "phone": "Foon",
        "account_type": "Tipe rekening",
        "role": "Rol",
        "terms_consent": "Toestemming vir bepalings",
        "terms_consented_at": "Bepalings toegestem by",
        "created_at": "Geskep by",
        "id": "ID"
      }
    },
    "ExportUserDialog": {
      "title": "Voer gebruikersdata uit",
      "description": "Die filters wat op die tabel toegepas word, is van toepassing op die uitvoer. Kies die datavelde wat u wil uitvoer",
      "presets": "Vooraf instellings",
      "presets_options": {
        "consents": "Toestemmings",
        "assessment_tools": "Assesseringstotale",
        "research": "Navorsing",
        "none": "Geen"
      },
      "sort": "Rangskik",
      "dialog_items": {
        "tenant_data": {
          "title": "Huurder data",
          "tenant": "Huurder",
          "institution": "Instelling",
          "cohort": "Kohort"
        },
        "personal_information": {
          "title": "Persoonlike inligting",
          "first_name": "Voornaam",
          "last_name": "Van",
          "email": "E-pos",
          "cell_phone_authentication": "Telefoon (verifikasie)",
          "cell_phone_profile": "Telefoon (profiel)",
          "date_of_birth": "Geboortedatum",
          "id_number": "ID nommer",
          "nationality": "nasionaliteit",
          "account_type": "Tipe rekening",
          "voucher_code": "Koopbewys kode",
          "role": "Rol",
          "time_stamps": "Tydstempels",
          "last_login": "Laaste aanmelding"
        },
        "choices": {
          "title": "Keuses",
          "subject_choices": "Vakkeuses",
          "favorite_careers": "Gunsteling loopbane",
          "interests": "Belange"
        },
        "research": {
          "title": "Navorsing",
          "research_profile": "Navorsingsprofiel",
          "research_assessment": "Navorsingsassessering",
          "research_consent": "Toestemming vir navorsing"
        },
        "consents": {
          "title": "Toestemmings",
          "terms_consent": "Toestemming vir bepalings",
          "competition_consent": "Toestemming vir kompetisie",
          "parental_consent": "Toestemming van ouers",
          "internal_marketing_consent": "Toestemming vir interne bemarking",
          "marketing_consent": "Toestemming vir bemarking",
          "custom_consent": "Persoonlike toestemming"
        },
        "reports": {
          "title": "Assesseringsdata",
          "worker_type": "Werker tipe",
          "big_5": "Groot 5",
          "entrepreneur": "Entrepreneur",
          "learning_styles": "Leerstyle",
          "perceiving_emotion": "Waarneming van emosie",
          "managing_emotion": "Die bestuur van emosie",
          "understanding_emotion": "Verstaan emosie",
          "rock_paper_scissors": "Rotspapier skêr",
          "maze": "Doolhof",
          "all_data": "Alle data",
          "totals": "Totale",
          "none": "Geen"
        },
        "researchAssessments": {
          "title": "Navorsingsassesserings",
          "interests": "Belange",
          "ipip": "IPIP"
        }
      },
      "download_csv": "Laai CSV af",
      "activeFilters": "Aktiewe filters:"
    },
    "UserSettingsPage": {
      "title": "Instellings",
      "theme_label": "Tema",
      "language": "Taal",
      "preferences": "Voorkeure",
      "change_password": "Verander wagwoord",
      "change_password_info": "Klik op die knoppie hieronder om u wagwoord te verander.",
      "dark": "Donker",
      "light": "Lig",
      "two_factor_auth": "Tweefaktor-verifikasie",
      "two_factor_auth_warning": "Dit word sterk aanbeveel om 2FA op te stel. Laai die Google Authenticator of Microsoft Authenticator af."
    },
    "ChangePassword": {
      "title": "Verander wagwoord",
      "old_password": "Ou wagwoord",
      "new_password": "Nuwe wagwoord",
      "new_password_confirm": "Bevestig nuwe wagwoord",
      "password_required_least_6": "Wagwoord moet minstens 6 karakters lank wees",
      "password_capital_letter_required": "Wagwoord moet ten minste een hoofletter bevat",
      "password_lowercase_letter_required": "Wagwoord moet ten minste een kleinletter bevat",
      "password_number_required": "Wagwoord moet ten minste een nommer bevat",
      "password_match_required": "Wagwoorde moet ooreenstem"
    },
    "TwoFactorAuth": {
      "title": "Tweefaktor-verifikasie",
      "enable_2fa": "Aktiveer 2FA",
      "already_enabled": "Reeds geaktiveer vir hierdie rekening.",
      "step_1": "Skandeer hierdie QR-kode met jou verifikasieprogram.",
      "step_2": "Voer die kode in wat deur u verifikasieprogram getoon word."
    },
    "ForgotPasswordPage": {
      "reset_password": "Stel jou wagwoord terug",
      "follow_steps": "Volg die stappe om weer toegang tot u rekening te kry.",
      "enter_details": "Voer asseblief jou besonderhede in",
      "reset_your_password": "Stel jou wagwoord terug",
      "enter_otp_to_reset": "Voer jou OTP (One Time Pin) in om jou wagwoord terug te stel"
    },
    "HelpPage": {
      "title": "Help",
      "system_info": "Hierdie stelsel is ontwerp om u te help om gebruikers, huurders, instellings en kohorte op 'n doeltreffende en gebruikersvriendelike manier te bestuur. Hier is 'n kort oorsig van die verskillende komponente van die stelsel:",
      "users_info": "Gebruikers: Gebruikers is individue wat die stelsel gebruik om hul studente of kurrikulum te bestuur. Elke gebruiker behoort aan 'n huurder.",
      "tenants_info": "Huurders: Huurders is groepe gebruikers wat aan dieselfde instelling behoort. Elke huurder word met 'n instelling geassosieer.",
      "institutions_info": "Instellings: Instellings is organisasies wat die stelsel gebruik om hul studente en kurrikulum te bestuur. Elke instelling kan verskeie huurders hê.",
      "cohorts_info": "Kohorte: Kohorte is groepe studente wat vir dieselfde kursus of program ingeskryf is. Kohorte word deur gebruikers binne 'n huurder geskep.",
      "user_access_info": "As gebruiker van die stelsel het u toegang tot verskillende funksies, afhangende van u rol en toestemmings. Hier is 'n paar van die belangrikste take wat u met behulp van die webbeheerpaneel kan uitvoer:",
      "manage_users": "Bestuur gebruikers: U kan gebruikers binne u huurder byvoeg, wysig of verwyder. U kan ook rolle en toestemmings aan gebruikers toewys.",
      "manage_tenants": "Bestuur huurders: U kan nuwe huurders binne u instelling skep, en u kan die gebruikers binne elke huurder bestuur.",
      "manage_institutions": "Bestuur instellings: U kan die verskillende huurders wat met u instelling geassosieer word, besigtig en bestuur, en u kan die instellings en konfigurasie vir u instelling bestuur.",
      "manage_cohorts": "Bestuur kohorte: U kan kohorte binne u huurder skep, redigeer of uitvee, en u kan die studente en kurrikulum binne elke kohort bestuur.",
      "additional_info": "Benewens hierdie kernfunksies, bevat die webbeheerpaneel ook verslagdoenings- en ontledingsinstrumente waarmee u die vordering van studente kan opspoor, kursusprestasie kan monitor en areas vir verbetering kan identifiseer.",
      "conclude_info": "Ons hoop dat hierdie hulpteks u 'n basiese begrip van die student en kurrikulumbestuur gegee het\n              stelsel en hoe om die webbeheerpaneel te gebruik om jou gebruikers, huurders, instellings en kohorte te bestuur. As u enige vrae het of verdere hulp benodig, moet asseblief nie huiwer om ons ondersteuningspan te kontak vir hulp nie."
    },
    "LoadingPage": {
      "title": "Kon nie die program laai nie",
      "subtitle": "Gaan asseblief jou internetverbinding na en probeer weer.",
      "if_issue_persists": "As hierdie probleem voortduur, moet u {0}",
      "contact_support": "Kontak ondersteuning",
      "reload_button": "Herlaai"
    },
    "CohortTablePage": {
      "title": "Kohorte",
      "table_headings": {
        "_id": "ID",
        "title": "Titel",
        "slug": "Slak",
        "tenant": "Huurder",
        "institution": "Instelling",
        "created_at": "Geskep by"
      }
    },
    "CouponTablePage": {
      "title": "Koepons",
      "search": "Soek"
    },
    "DashboardPage": {
      "title": "Paneelbord"
    },
    "ExportAccessCodes": {
      "title": "Toegangskodes vir uitvoer",
      "subtitle": "Kies die velde wat u wil uitvoer.",
      "active_filters": "Aktiewe filters:",
      "format": "Formaat"
    },
    "AppConfigurationPage": {
      "title": "Programkonfigurasie",
      "general_title": "Algemene",
      "app_name": "Toepassing Naam",
      "support_email": "Ondersteun e-pos",
      "onboarding": "Aan boord",
      "authentication": "Waarmerking",
      "localisation": "Lokalisering",
      "integrations": "Integrasies",
      "features": "Kenmerke",
      "theme": "Tema",
      "overview": "Oorsig"
    },
    "ConsentSelector": {
      "title": "Toestemmings",
      "terms": "Terme",
      "marketing": "Bemarking",
      "parental": "Ouerlike",
      "research": "Navorsing",
      "required": "Vereis",
      "optional": "Opsioneel",
      "disabled": "Gedeaktiveer",
      "subtitle": "Hierdie toestemmings word aan die gebruiker getoon tydens aanmelding.",
      "internalMarketing": "Interne bemarking"
    },
    "OnboardingMethodsSelector": {
      "title": "Aan boord metodes",
      "redeem": "Gebruik koopbewys",
      "payment": "Aanlyn betaling",
      "subtitle": "Hierdie metodes is beskikbaar vir die gebruiker tydens aan boord.",
      "redeem_description": "Gebruikers kan hul betaalde rekening met 'n kode aktiveer",
      "payment_description": "Gebruiker kan beskikbare produkte koop"
    },
    "InitialProfileSelector": {
      "title": "Aanvanklike profiel",
      "email": "E-pos",
      "phone": "Foon",
      "nationality": "nasionaliteit",
      "subtitle": "Hierdie velde word verpligtend gemaak tydens aan boord.",
      "firstName": "Voornaam",
      "lastName": "Van",
      "birthDate": "Geboortedatum",
      "rsaIdNumber": "Suid-Afrikaanse ID-nommer"
    },
    "AuthMethodsSelector": {
      "title": "Auth metodes",
      "basic": "Basies (e-pos en selfoon)",
      "snapplify": "Snapplify",
      "subtitle": "Hierdie toestemmings word aan die gebruiker getoon tydens aanmelding.",
      "azureAdb2c": "Azure AD B2C",
      "sbOpenid": "SB OpenID",
      "fmOauth": "FM OAuth",
      "oidc": "Maak ID Connect oop",
      "auth_providers_updated": "Auth-verskaffers opgedateer"
    },
    "LocalesSelector": {
      "title": "Plekke",
      "application_locales": "Aansoek plekke",
      "assessment_locales": "Assessering plekke",
      "default_locale": "Standaard plek",
      "save_message": "Locales opgedateer",
      "default_assessment_locale": "Standaard assessering lokaal",
      "application_locales_title": "Aansoek plekke",
      "assessment_locales_title": "Assesseringslokale",
      "application_locales_info": "Die volgende instellings is van toepassing op die toepassing as geheel.",
      "assessment_locales_info": "Die volgende instellings is slegs van toepassing op die assesserings."
    },
    "AvailableCountriesSelector": {
      "title": "Beskikbare lande",
      "label": "Lande"
    },
    "DefaultCountrySelector": {
      "title": "Standaard land",
      "label": "Lande"
    },
    "FeatureSelector": {
      "title": "Kenmerke",
      "worker_type_report": "Werker tipe verslag",
      "entrepreneur_report": "Entrepreneur-verslag",
      "personality_traits_report": "Verslag oor persoonlikheidseienskappe",
      "areas_of_development_report": "Gebiede van ontwikkelingsverslag",
      "learning_styles_report": "Verslag oor leerstyle",
      "cognitive_ability_report": "Kognitiewe vermoë verslag",
      "emotional_intelligence_report": "Verslag oor emosionele intelligensie",
      "value_scales_report": "Waardeskale verslag",
      "career_explorer": "Loopbaan ontdekkingsreisiger",
      "subject_choice_explorer": "Vakkeuse ontdekkingsreisiger",
      "tertiary_institution_explorer": "Tersiêre Instelling Ontdekkingsreisiger",
      "bursaries_explorer": "Beurse ontdekkingsreisiger",
      "resources_explorer": "Resources Explorer",
      "skills_explorer": "Skills Explorer",
      "funding_explorer": "Befondsing van ontdekkingsreisiger",
      "reports_explorer": "Verslae Ontdekkingsreisiger",
      "upliftment_provider_explorer": "Opheffingsverskaffer Explorer",
      "course_discounts": "Kursus afslag",
      "dashboard": "Paneelbord",
      "assessment_dashboard": "Assessering dashboard",
      "profile": "Profiel",
      "articles": "Artikels",
      "career_matches": "Loopbaan wedstryde",
      "alison": "Alison",
      "cv": "CV",
      "features_updated": "Kenmerke opgedateer"
    },
    "TertiaryInstitutionFeatureSettings": {
      "title": "Tersiêre instelling instellings",
      "visible_countries_title": "Sigbare lande",
      "visible_countries_hint": "Wys instellings in hierdie lande",
      "save_message": "Beskikbare lande suksesvol gered",
      "empty_message": "Kies asseblief ten minste een land"
    },
    "ThemeEditor": {
      "title": "Kleur temas",
      "light": "Lig",
      "dark": "Donker",
      "primary": "Primêre",
      "secondary": "Sekondêre",
      "background": "Agtergrond",
      "surface": "Oppervlak",
      "error": "Fout",
      "success": "Sukses",
      "info": "Inligting",
      "warning": "Waarskuwing",
      "confirm_title": "Pas veranderinge aan tema toe?",
      "confirm_text": "Hierdie verandering sal regstreeks na alle gebruikers gaan",
      "gradient-start": "Gradiënt Begin",
      "gradient-end": "Gradiënt Einde",
      "app-header-bar": "App Header Bar",
      "app-header-bar-text": "Program-kopbalkteks",
      "bar-gradient-start": "Bar Gradient Begin",
      "bar-gradient-end": "Bar Gradient End",
      "app-header-bar-link": "App Header Bar Skakel"
    },
    "ImageEditor": {
      "title": "Beelde",
      "logo": "Logo",
      "logo_full": "Volledige logo",
      "dash_login": "Streep-aanmelding",
      "favicon": "Favicon",
      "app_icon": "App-ikoon",
      "light_app_icon": "Ligte toepassingsikoon",
      "light_logo": "Ligte logo"
    },
    "IntegrationSelector": {
      "title": "Integrasies",
      "confirm_title": "Bevestig",
      "confirm_text": "Bevestig asseblief dat u die konfigurasie wil verander",
      "d6": "D6",
      "update_message": "Integrasies opgedateer",
      "dlocal": "DLocal",
      "payfast": "PayFast"
    },
    "AccessCodeDetailPage": {
      "back_breadcrumb": "Koopbewyse",
      "change_account_type": "Verander rekeningtipe",
      "change_research_assessments": "Verander navorsingsassesserings",
      "delete_voucher": "Vee koopbewys uit",
      "message_warning": "U is op die punt om uit te vee:"
    },
    "CohortSearchDropdown": {
      "instruction": "Kies asseblief 'n instelling wat kohorte bevat."
    },
    "InstitutionSearchDropdown": {
      "instruction": "Soek asseblief vir 'n instelling."
    },
    "TenantSearchDropdown": {
      "instruction": "Soek asseblief vir 'n huurder."
    },
    "AccessCodesUsageRatioMetricTile": {
      "title": "Koopbewyse uitgereik",
      "metric_title": "Koopbewyse gebruik / koopbewyse uitgereik",
      "info": "Aantal koopbewyse uitgereik en gebruik."
    },
    "Big5CompletionRatioMetricTile": {
      "title": "Persoonlikheidseienskap voltooiingsverhouding (%)",
      "metric_title": "Voltooiings / Rekeninge geskep",
      "info": "Aantal gebruikers wat 'n rekening geskep het en die Persoonlikheidseienskappe in die gegewe tydperk geneem het."
    },
    "HollandCodeCompletionRatioMetricTile": {
      "title": "Werker tipe voltooiingsverhouding (%)",
      "metric_title": "Voltooiings / Rekeninge geskep",
      "info": "Aantal gebruikers wat 'n rekening geskep het en die Werkertipe in die verskafde tydperk geneem het."
    },
    "ReturningUsersMetricTile": {
      "title": "Terugkerende gebruikers",
      "metric_title": "Gebruiker aanmeldings",
      "info": "Die aantal gebruikers wat meer as een keer aangemeld het sedert hulle hul rekening geskep het."
    },
    "SignupsMetricTile": {
      "title": "Inskrywings",
      "metric_title": "Rekeninge geskep",
      "info": "Die hoeveelheid nuwe rekeninge wat geskep is."
    },
    "UsersHasSelectedSchoolSubjectsMetricTile": {
      "title": "Vakke Geselekteerde Voltooiingsverhouding (%)",
      "metricTitle": "Gebruikers gekies / rekeninge geskep",
      "info": "Aantal gebruikers wat 'n rekening geskep het en hul vakke in die gegewe tydperk gekies het."
    },
    "UserLoginsMetricTile": {
      "title": "Aanmeldings",
      "metricTitle": "Gebruiker aanmeldings",
      "info": "Die aantal gebruikers wat aangemeld het."
    },
    "ReportPeriodFilterBar": {
      "today": "Vandag",
      "yesterday": "Gister",
      "custom_date": "Pasgemaakte datum",
      "7D": "7D",
      "30D": "30D",
      "3M": "3M",
      "6M": "6M",
      "12M": "12M",
      "24M": "24M",
      "time_frame": "Tydsraamwerk",
      "filter_by": "Filter Deur",
      "open_filters": "Maak filters oop"
    },
    "ChangeResearchAssessment": {
      "toggle_research_heading": "Navorsing",
      "title": "Navorsingsassesserings",
      "instruction": "Kies die assesserings waarvoor u resultate wil versamel.",
      "assessments": {
        "holland_code": "Werker tipe",
        "big_5": "Persoonlikheidseienskappe",
        "learning_styles": "Leerstyle",
        "managing_emotion": "Die bestuur van emosies",
        "understanding_emotion": "Verstaan emosies",
        "perceiving_emotion": "Waarneming van emosies",
        "rock_paper_scissors": "Rotspapier skêr",
        "maze": "Doolhof",
        "steu_b": "Steu B",
        "stem_b": "Stam B",
        "entrepreneur-holland-code": "Entrepreneur Holland Kode",
        "ipip": "IPIP",
        "interests": "Belange",
        "entrepreneurial_intent": "Entrepreneuriese voorneme",
        "value_scales": "Waarde skale"
      }
    },
    "CreateVoucherDialog": {
      "account_type": "Tipe rekening",
      "quantity": "Hoeveelheid",
      "tenanted": "Huurder",
      "tenant_settings": "Huurder-instellings",
      "tenant_settings_text_caption": "Kies die huurder, instelling en kohort waaraan u hierdie kode wil koppel.",
      "research": "Navorsing",
      "research_assessments": "Navorsingsassesserings",
      "research_settings_text_caption": "Kies die assesserings waarvoor u resultate wil versamel.",
      "belongs_to_tenant": "Behoort aan Huurder",
      "used_for_research": "Gebruik vir navorsing",
      "assessments": {
        "holland_code": "Werker tipe",
        "big_5": "Persoonlikheidseienskappe",
        "learning_styles": "Leerstyle",
        "managing_emotion": "Die bestuur van emosies",
        "understanding_emotion": "Verstaan emosies",
        "perceiving_emotion": "Waarneming van emosies",
        "rock_paper_scissors": "Rotspapier skêr",
        "maze": "Doolhof",
        "value_scales": "Waarde skale"
      },
      "snackbarCompletedMessage": "Koopbewys is suksesvol geskep. Hoeveelheid koopbewyse:",
      "snackbarErrorMessage": "'N Fout het voorgekom tydens die skep van die koopbewys"
    },
    "DeleteVoucher": {
      "message_instruction": "Tik die koopbewyskode in:"
    },
    "MainNavigation": {
      "dashboard": "Paneelbord",
      "users": "Gebruikers",
      "vouchers": "Koopbewyse",
      "tenants": "Huurders",
      "cohorts": "Kohorte",
      "institutions": "Instellings",
      "insights": "Insigte",
      "coupons": "Koepons",
      "configuration": "Konfigurasie",
      "help": "Help",
      "logout": "Afmelding",
      "manage": "Bestuur",
      "account": "Rekening instellings"
    },
    "RevokeManagerPermissionsDialog": {
      "title": "Herroep bestuurder toestemmings"
    },
    "DownloadAssessmentDialog": {
      "title": "Laai assesseringsverslag af",
      "download_instruction": "Klik op bevestig om die assessering af te laai.",
      "report_downloading": "Die verslag word gegenereer."
    },
    "SubjectChoiceDistributionReport": {
      "title": "Verspreiding van vakkeuses",
      "math_vs_math_literacy": "Wiskunde vs Wiskunde Geletterdheid",
      "first_language": "Eerste taal",
      "second_language": "Tweede Taal",
      "no_data": "Geen data is beskikbaar vir die filters wat u gekies het nie",
      "elective_choices": "Elektiewe keuses",
      "mathematics": "Wiskunde"
    },
    "MetricTile": {
      "time_range": "Tydsbestek"
    },
    "PersonalityTraitsDistributionGraph": {
      "agreeableness": "Aangenaamheid",
      "conscientiousness": "Pligsgetrouheid",
      "open_mindedness": "Oopkop",
      "extraversion": "Ekstraversie",
      "negative_emotionality": "Negatiewe emosionaliteit"
    },
    "GroupDashboardReport": {
      "overview": "Oorsig",
      "subject_choices": "Vakkeuses",
      "worker_types": "Werkers tipes",
      "personality_types": "Persoonlikheid tipes"
    },
    "PersonalityTraitDistributionCard": {
      "title": "Persoonlikheidseienskappe Verspreiding"
    },
    "SubjectChoiceDistributionCard": {
      "title": "Verspreiding van vakkeuses"
    },
    "WorkTypeDistributionCard": {
      "title": "Werker tipe verspreiding"
    },
    "UsageSummaryCard": {
      "title": "Gebruik opsomming",
      "accounts_created": "Rekeninge geskep",
      "voucher_codes_issued": "Koopbewyskodes uitgereik",
      "voucher_codes_used": "Koopbewyskodes wat gebruik word",
      "subject_choice_completions": "Vakkeuse voltooiings",
      "worker_type_completions": "Werker tipe voltooiings",
      "worker_type_rate": "Werker tipe tarief",
      "subject_choice_rate": "Vakkeuse koers"
    },
    "PasswordRecoveryForm": {
      "phone_recovery_code_title": "'N OTP is na u telefoon gestuur:",
      "invalid_recovery_code": "Herstel misluk - Ongeldige kode verskaf. Probeer asseblief weer.",
      "otp_required": "OTP word benodig",
      "back_to_login": "Terug na Aanmelding",
      "phone_number": {
        "label": "Telefoonnommer"
      },
      "recovery_code": {
        "label": "Herstel kode"
      },
      "new_password": {
        "label": "Nuwe wagwoord"
      },
      "submit": "Dien in",
      "required": "Hierdie veld is nodig",
      "your_code_has_been_sent_to": "Jou OTP is gestuur na",
      "password_reset_successfully": "Jou wagwoord is suksesvol opgedateer",
      "please_check_your_spam": "Gaan asseblief jou rommel- of strooipos-e-posvouer na as jy nie jou OTP ontvang nie",
      "save_new_password": "Stoor nuwe wagwoord",
      "continue_to_login": "Gaan voort om aan te meld"
    },
    "DownloadCvDialog": {
      "title": "Laai CV af",
      "download_instruction": "Klik op bevestig om die CV af te laai.",
      "report_downloading": "Die CV word gegenereer."
    },
    "GeneralAppConfigurationForm": {
      "title": "Algemene",
      "app_name": "Toepassing Naam",
      "support_email": "Ondersteun e-pos",
      "save_message": "Algemene instellings opgedateer",
      "app_url": "Program-URL"
    },
    "AzureADB2CConfigForm": {
      "title": "Azure AD B2C"
    },
    "SBOpenIDAuthConfigForm": {
      "title": "SB OpenID"
    },
    "SubjectChoiceNavigatorConfigForm": {
      "title": "Vakkeuse",
      "available_curricula": "Beskikbare kurrikulums"
    },
    "SkillsExplorerConfigForm": {
      "title": "Skills Explorer",
      "show_paid_courses": "Wys betaalde kursusse"
    },
    "ManageUser": {
      "title": "Bestuur gebruiker",
      "current_permissions": "Huidige regte",
      "save_permissions": "Stoor toestemmings",
      "organisationDetails": "Organisasie besonderhede"
    },
    "OrganisationDetailsCard": {
      "update_permissions": "Dateer toestemmings op om besonderhede te sien"
    }
  },
  "curriculums": {
    "kenyan_certificate_of_secondary_education": {
      "title": "Keniaanse sertifikaat vir sekondêre onderwys",
      "subjects": {
        "mathematics": "Wiskunde",
        "english": "Engels",
        "kiswahili": "Kiswahili",
        "physics": "Fisika",
        "biology": "Biologie",
        "chemistry": "Chemie",
        "geography": "Aardrykskunde",
        "history_and_government": "Geskiedenis en regering",
        "christian_religious_studies": "Christelike Godsdiensstudies",
        "hindu_religious_studies": "Hindoe-godsdiensstudies",
        "islamic_religious_studies": "Islamitiese godsdiensstudies",
        "home_science": "Tuis Wetenskap",
        "art_and_design": "Kuns en Ontwerp",
        "agriculture": "Landbou",
        "woodwork": "Houtwerk",
        "metal_work": "Metaal werk",
        "building_construction": "Bou konstruksie",
        "power_mechanics": "Kragmeganika",
        "electricity": "Elektrisiteit",
        "drawing_and_design": "Teken en ontwerp",
        "aviation_technology": "Lugvaart Tegnologie",
        "computer_studies": "Rekenaarstudies",
        "french": "Franse",
        "german": "Duitse",
        "arabic": "Arabies",
        "kenyan_sign_language": "Keniaanse Gebaretaal",
        "music": "Musiek",
        "business_studies": "Besigheidstudies"
      },
      "subject_groups": {
        "mathematics": "Wiskunde",
        "english": "Engels",
        "kiswahili": "Kiswahili",
        "science_1": "Wetenskap 1",
        "science_2": "Wetenskap 2",
        "humanities": "Geesteswetenskappe",
        "elective_1": "Keusevak 1",
        "elective_2": "Keusevak 2"
      }
    },
    "national_senior_certificate": {
      "title": "Nasionale Senior Sertifikaat",
      "subjects": {
        "english_first_language": "Engels",
        "afrikaans_first_language": "Afrikaans",
        "ndebele_first_language": "isiNdebele",
        "sesotho_first_language": "Sesotho",
        "isixhosa_first_language": "Isixhosa",
        "isizulu_first_language": "isiZulu",
        "sepedi_first_language": "Sepedi",
        "setswana_first_language": "Setswana",
        "siswati_first_language": "siSwati",
        "tshivenda_first_language": "Tshivenda",
        "xitsonga_first_language": "Xitsonga",
        "english_second_language": "Engels",
        "afrikaans_second_language": "Afrikaans",
        "ndebele_second_language": "isiNdebele",
        "sesotho_second_language": "Sesotho",
        "isixhosa_second_language": "Isixhosa",
        "isizulu_second_language": "isiZulu",
        "sepedi_second_language": "Sepedi",
        "setswana_second_language": "Setswana",
        "siswati_second_language": "siSwati",
        "tshivenda_second_language": "Tshivenda",
        "xitsonga_second_language": "Xitsonga",
        "life_orientation": "Lewensoriëntering",
        "mathematics": "Wiskunde",
        "mathematical_literacy": "Wiskundige Geletterdheid",
        "ap_afrikaans": "AP Afrikaans",
        "ap_physics": "AP Fisika",
        "ap_english": "AP Afrikaans",
        "ap_maths": "AP Wiskunde",
        "urdu": "Oerdoe",
        "telugu": "Teloegoe",
        "tamil": "Tamil",
        "spanish": "Spaans",
        "portuguese": "Portugese",
        "latin": "Latyn",
        "italian": "Italiaans",
        "hindi": "Hindi",
        "hebrew": "Hebreeus",
        "gujarati": "Goedjarati",
        "german": "Duitse",
        "french": "Franse",
        "arabic": "Arabiese",
        "agricultural_technology": "Landbou Tegnologie",
        "engineering_graphics_and_design": "Ingenieursgrafika en -ontwerp",
        "music": "Musiek",
        "dramatic_arts": "Dramatiese Kunste",
        "dance_studies": "Dans Studies",
        "religion_studies": "Godsdiens Studies",
        "consumer_studies": "Verbruikerstudies",
        "economics": "Ekonomie",
        "information_technology": "Inligtingstegnologie",
        "computer_applications_technology": "Rekenaartoepassingstegnologie",
        "electrical_technology": "Elektriese Tegnologie",
        "visual_arts": "Visuele Kunste",
        "physical_sciences": "Fisiese Wetenskappe",
        "design": "Ontwerp",
        "mechanical_technology": "Meganiese tegnologie",
        "civil_technology": "Siviele Tegnologie",
        "business_studies": "Besigheidstudies",
        "life_sciences": "Lewenswetenskappe",
        "agricultural_sciences": "Landbouwetenskappe",
        "hospitality_studies": "Gasvryheidstudies",
        "geography": "Aardrykskunde",
        "tourism": "Toerisme",
        "history": "Geskiedenis",
        "agricultural_management_practices": "Landboubestuurspraktyke",
        "accounting": "Rekeningkundige"
      },
      "subject_groups": {
        "first_language": "Eerste taal",
        "second_language": "Tweede Taal",
        "life_orientation": "Lewensoriëntering",
        "mathematics": "Wiskunde",
        "elective_1": "Keusevak 1",
        "elective_2": "Keusevak 2",
        "elective_3": "Keusevak 3",
        "elective_4": "Keusevak 4",
        "elective_5": "Keusevak 5"
      }
    },
    "cambridge": {
      "title": "Cambridge",
      "subject_groups": {
        "group_1": "Groep 1",
        "group_2": "Groep 2",
        "group_3": "Groep 3",
        "group_4": "Groep 4",
        "group_5": "Groep 5",
        "ungrouped": "Ongegroepeer"
      },
      "subjects": {
        "english_language_and_literature_a_as": "Engelse taal en letterkunde A/AS",
        "english_language_a_as": "Engelse taal A/AS",
        "english_first_language_igcse": "Engels Eerste Taal IGCSE",
        "afrikaans_second_language": "Afrikaans",
        "urdu_second_language": "Oerdoe",
        "turkish_second_language": "Turkse",
        "thai_second_language": "Thai",
        "telugu_second_language": "Teloegoe",
        "tamil_second_language": "Tamil",
        "spanish_second_language": "Spaans",
        "swahili_second_language": "Swahili",
        "russian_second_language": "Russiese",
        "nepali_second_language": "Nepalees",
        "marati_second_language": "Marati",
        "malay_second_language": "Maleisiese",
        "korean_second_language": "Koreaanse",
        "japanese_second_language": "Japannese",
        "italian_second_language": "Italiaans",
        "isizulu_second_language": "isiZulu",
        "hindi_second_language": "Hindi",
        "greek_second_language": "Grieks",
        "german_second_language": "Duitse",
        "french_second_language": "Franse",
        "dutch_second_language": "Hollands",
        "chinese_second_language": "Chinees",
        "arabic_second_language": "Arabies",
        "latin_second_language_igcse": "Latynse IGCSE",
        "accounting_a_as": "Rekeningkunde A / ASS",
        "biology_a_as": "Biologie A / AS",
        "physics_a_as": "Fisika A/AS",
        "physical_science_igcse": "Fisiese Wetenskap IGCSE",
        "physics_igcse": "Fisika IGCSE",
        "mathematics_igcse": "Wiskunde IGCSE",
        "mathematics_a_as": "Wiskunde A/AS",
        "co_ordinated_science_igcse": "Gekoördineerde Wetenskap IGCSE",
        "combined_science_igcse": "Gekombineerde Wetenskap IGCSE",
        "biology_igcse": "Biologie IGCSE",
        "chemistry_igcse": "Chemie IGCSE",
        "art_and_design_a_as": "Kuns en Ontwerp A/AS",
        "chemistry_a_as": "Chemie A / AS",
        "music_a_as": "Musiek A/AS",
        "history_a_as": "Geskiedenis A/AS",
        "geography_a_as": "Aardrykskunde A/AS",
        "music_igcse": "Musiek IGCSE",
        "history_igcse": "Geskiedenis IGCSE",
        "environmental_management_igcse": "Omgewingsbestuur IGCSE",
        "economics_igcse": "Ekonomie IGCSE",
        "geography_igcse": "Aardrykskunde IGCSE",
        "economics_a_as": "Ekonomie A/AS",
        "art_and_design_igcse": "Kuns en Ontwerp IGCSE",
        "business_a_as": "Besigheid A / AS",
        "computer_science_a_as": "Rekenaarwetenskap A/AS",
        "information_technology_a_as": "Inligtingstegnologie A/AS",
        "further_mathematics_a_as": "Verdere Wiskunde A/AS",
        "religious_studies_igcse": "Godsdiensstudies IGCSE",
        "divinity_a_as": "Goddelikheid A/AS",
        "design_and_technology_igcse": "Ontwerp en Tegnologie IGCSE",
        "computer_science_igcse": "Rekenaarwetenskap IGCSE",
        "business_studies_igcse": "Besigheidstudies IGCSE",
        "accounting_igcse": "Rekeningkundige IGCSE",
        "drama_cambri_a_as": "Drama A/AS",
        "literature_in_english_igcse": "Literatuur in Engels IGCSE",
        "travel_and_tourism_a_as": "Reis en toerisme A / AS",
        "travel_and_tourism_igcse": "Reis & Toerisme IGCSE",
        "drama_igcse": "Drama IGCSE",
        "information_and_communication_technology_igcse": "Inligting- en kommunikasietegnologie IGCSE"
      }
    },
    "pre_international_baccalaureate": {
      "title": "Pre Internasionale Baccalaureaat",
      "subject_groups": {
        "group_1": "Groep 1",
        "group_2": "Groep 2",
        "group_3": "Groep 3",
        "group_4": "Groep 4",
        "group_5": "Groep 5",
        "group_6": "Groep 6"
      },
      "subjects": {
        "english": "Engelse taal en letterkunde Hl",
        "french": "Franse",
        "business_management": "Besigheidsbestuur",
        "geography": "Aardrykskunde",
        "history": "Geskiedenis",
        "philosophy": "Filosofie",
        "biology": "Biologie",
        "chemistry": "Chemie",
        "physics": "Fisika",
        "computer_science": "Rekenaarwetenskap",
        "design_and_technology": "Ontwerp en tegnologie",
        "mathematics_core": "Wiskunde kern",
        "further_studies_mathematics": "Verdere Studies Wiskunde",
        "music": "Musiek",
        "visual_arts": "Visuele Kunste",
        "theatre_studies": "Teaterstudies",
        "english_language_and_literature_hl": "Engelse taal en letterkunde Hl"
      }
    },
    "international_baccalaureate": {
      "title": "Internasionale Baccalaureaat",
      "subject_groups": {
        "group_1": "Groep 1",
        "group_2": "Groep 2",
        "group_3": "Groep 3",
        "group_4": "Groep 4",
        "group_5": "Groep 5",
        "group_6": "Groep 6"
      },
      "subjects": {
        "english_a_language_and_literature_sl": "Engels 'n Taal En Letterkunde SL",
        "english_a_language_and_literature_hl": "Engels 'n Taal en letterkunde HL",
        "french_a_language_and_literature_hl": "Frans 'n taal en letterkunde HL",
        "french_a_language_and_literature_sl": "Frans 'n Taal en letterkunde SL",
        "french_ab_initio": "Frans Ab Initio",
        "french_b": "Frans B",
        "business_management_hl": "Ondernemingsbestuur HL",
        "business_management_sl": "Ondernemingsbestuur SL",
        "economics_hl": "Ekonomie HL",
        "economics_sl": "Ekonomie SL",
        "geography_hl": "Aardrykskunde HL",
        "geography_sl": "Aardrykskunde SL",
        "history_hl": "Geskiedenis HL",
        "history_sl": "Geskiedenis SL",
        "psychology_hl": "Sielkunde HL",
        "psychology_sl": "Sielkunde SL",
        "social_and_cultural_anthropology_hl": "Sosiale en kulturele antropologie HL",
        "digital_society_hl": "Digitale Vereniging HL",
        "digital_society_sl": "Digitale samelewing SL",
        "global_politics_hl": "Globale politiek HL",
        "global_politics_sl": "Globale politiek SL",
        "philosophy_sl": "Filosofie SL",
        "philosophy_hl": "Filosofie HL",
        "world_religions_hl": "Wêreldgodsdienste HL",
        "world_religions_sl": "Wêreldgodsdienste SL",
        "environmental_systems_and_societies_sl": "Omgewingstelsels en samelewings SL",
        "environmental_systems_and_societies_hl": "Omgewingstelsels en samelewings HL",
        "biology_hl": "Biologie HL",
        "biology_sl": "Biologie SL",
        "chemistry_hl": "Chemie HL",
        "chemistry_sl": "Chemie SL",
        "computer_science_hl": "Rekenaarwetenskap HL",
        "computer_science_sl": "Rekenaarwetenskap SL",
        "design_technology_hl": "Ontwerp Tegnologie HL",
        "design_technology_sl": "Ontwerp Tegnologie SL",
        "physics_hl": "Fisika HL",
        "physics_sl": "Fisika SL",
        "sports_exercise_and_health_science_hl": "Sportoefening en Gesondheidswetenskap HL",
        "sports_exercise_and_health_science_sl": "Sportoefening en gesondheidswetenskap SL",
        "mathematics_analysis_and_approaches_hl": "Wiskunde-analise en benaderings HL",
        "mathematics_analysis_and_approaches_sl": "Wiskunde-analise en benaderings SL",
        "mathematics_applications_and_interpretation_hl": "Wiskunde toepassings en interpretasie HL",
        "mathematics_applications_and_interpretation_sl": "Wiskunde toepassings en interpretasie SL",
        "film_hl": "Film HL",
        "film_sl": "Film SL",
        "music_hl": "Musiek HL",
        "music_sl": "Musiek SL",
        "theatre_hl": "Teater HL",
        "theatre_sl": "Teater SL",
        "visual_arts_hl": "Visuele Kunste HL",
        "visual_arts_sl": "Visuele Kunste SL",
        "social_and_cultural_sl": "Sosiale en kulturele antropologie SL"
      }
    }
  }
}