<template>
  <v-app v-if="store.appLoading || store.fatalError">
    <NetworkBusyProgressBar/>
    <LoadingPage :fatal-error="store.fatalError"/>
  </v-app>
  <v-app v-else>
    <NetworkBusyProgressBar/>
    <router-view/>
    <v-snackbar :timeout="4000" v-model="store.snackbarVisible">
      {{ store.snackbarMessage }}
      <template v-slot:actions>
        <v-btn
          color="primary"
          @click="store.snackbarVisible = false"
        >
          {{ $t("buttons.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script setup lang="ts">
import {onMounted} from "vue";

import NetworkBusyProgressBar from "./components/NetworkBusyProgressBar.vue";
import LoadingPage from "./pages/LoadingPage.vue";

import {useGlobalStore} from "@/store";
import {fetchConfig, setCachedConfig} from "@/lib/appConfiguration";
import {useTheme} from "vuetify";

const store = useGlobalStore();
const theme = useTheme();

async function updateAppConfiguration() {
  const config = await fetchConfig();
  store.appConfiguration = config;
  theme.themes.value.light.colors = config.themeLight.colors as typeof theme.themes.value.light.colors;
  setCachedConfig(config);
}

onMounted(async () => {
  await Promise.all([
    store.fetchUser(),
    updateAppConfiguration()
  ]);
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dp__calendar_item {
  &:hover {
    background-color: rgb(var(--v-theme-primary));
    color: white;
  }
  .dp__range_start {
    background-color: rgb(var(--v-theme-primary));
    color: white;
  }
  .dp__range_end {
    background-color: rgb(var(--v-theme-primary));
    color: white;
  }
  .dp__today {
    border-color: rgb(var(--v-theme-primary));
  }
}
.dp__overlay_cell_active {
  background-color: rgb(var(--v-theme-primary));
}

.v-btn {
  text-transform:none !important;
}

.v-btn--variant-outlined {
  border: thin solid #CCCCCC !important;
}
</style>
