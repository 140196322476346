<template #context-menu>
  <v-card class="px-16">
    <v-card-title>
      <v-row align-content="center">
        <v-col cols="11" class="justify-center">
          {{ $t('components.ExportUserDialog.title') }}
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>

      <div class="text-left text-body-2">
        <div>
          <b>{{ $t('components.ExportUserDialog.activeFilters') }}</b>
          <template v-for="column in cols">
            <v-chip
              :key="`${column.modelKey}_export_filter`"
              class="ml-2"
              size="small"
              v-if="column.filter && column.filterText">{{ column.title }}: <b> {{ column.filterText }}</b></v-chip>
          </template>
        </div>

        <div>
          <b>{{ $t('components.ExportAccessCodes.format') }}: </b> {{ $t(`common.${format}`) }}
        </div>
      </div>

      <div>
        <v-row class="mx-5">
          <v-col cols="3">
            <h3>{{ $t('components.ExportUserDialog.dialog_items.personal_information.title') }} </h3>
            <div v-for="option in exportOptions.personalInformation.options" :key="option.title">
              <v-checkbox
                v-model="selected[option.key]"
                :label="option.title"
                hide-details
                density="compact"
              ></v-checkbox>
            </div>
            <h3>{{ $t('components.ExportUserDialog.dialog_items.tenant_data.title') }} </h3>
            <div v-for="option in exportOptions.tenantData.options" :key="option.title">
              <v-checkbox
                v-model="selected[option.key]"
                :label="option.title"
                hide-details
                density="compact"
              ></v-checkbox>
            </div>
            <div v-if="isAdminOrRoot">
              <h3>{{exportOptions.research.title}} </h3>
              <div v-for="option in exportOptions.research.options" :key="option.title">
                <v-checkbox
                  :label="option.title"
                  v-model="selected[option.key]"
                  hide-details
                  density="compact"
                ></v-checkbox>
              </div>
            </div>
          </v-col>
          <v-col cols="3">
            <h3>{{exportOptions.accountSetup.title}} </h3>
            <div v-for="option in exportOptions.accountSetup.options" :key="option.title">
              <v-checkbox
                v-model="selected[option.key]"
                :label="option.title"
                hide-details
                density="compact"
              ></v-checkbox>
            </div>
            <h3>{{exportOptions.choices.title}} </h3>
            <div v-for="option in exportOptions.choices.options" :key="option.title">
              <v-checkbox
                v-model="selected[option.key]"
                :label="option.title"
                hide-details
                density="compact"
              ></v-checkbox>
            </div>
            <h3>{{exportOptions.consents.title}} </h3>
            <div v-for="option in exportOptions.consents.options" :key="option.title">
              <v-checkbox
                v-model="selected[option.key]"
                :label="option.title"
                hide-details
                density="compact"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="3">
            <h3>{{ $t("components.ExportUserDialog.dialog_items.reports.title") }}</h3>
            <v-list density="compact">
              <v-list-item
                v-for="report in exportOptions.reports"
                :key="report.title"
                density="compact"
              >
                <v-list-item-title>
                  {{ report.title }}
                </v-list-item-title>
                <v-list-item-action class="pl-4">
                  <v-radio-group
                    v-model="selected[report.slug]"
                    :inline="true"
                    hide-details
                  >
                    <v-radio
                      :label="$t('components.ExportUserDialog.dialog_items.reports.all_data')"
                      value="all"
                      hide-details
                    ></v-radio>
                    <v-radio
                      v-model="selected[report.slug]"
                      :label="$t('components.ExportUserDialog.dialog_items.reports.totals')"
                      value="totals"
                      hide-details
                    ></v-radio>
                    <v-radio
                      v-model="selected[report.slug]"
                      :label="$t('components.ExportUserDialog.dialog_items.reports.none')"
                      value="false"
                      hide-details
                    ></v-radio>
                  </v-radio-group>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col v-if="isAdminOrRoot" cols="3">
            <h3>{{ $t('components.ExportUserDialog.dialog_items.researchAssessments.title') }} </h3>
            <div v-for="option in exportOptions.researchAssessments" :key="option.title">
              <v-checkbox
                :label="option.title"
                v-model="selected[option.key]"
                hide-details
                density="compact"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn>
      <v-btn
        color="primary"
        data-pw="confirmExportButton"
        @click="downloadExcel"
      >
        {{ $t('buttons.confirm') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts" setup>
import {ref, PropType} from "vue";
import {Role, UserExportSelectors,} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import {ColumnsConfig} from "@/lib/pagination";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
const {t} = useI18n();
const selected = ref<UserExportSelectors>({});
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  cols: {
    type: Object as PropType<ColumnsConfig>,
    required: true
  },
  format: {
    type: String,
    required: true
  }
});

const emit = defineEmits<{
  (e: "download", selected: UserExportSelectors): void,
  (e: "close"): void;
}>();

selected.value = {
  uuid: false,
  email: false,
  phone: false,
  profileContactNumber: false,
  firstName: false,
  lastName: false,
  tenant: false,
  institution: false,
  cohort: false,
  birthDate: false,
  nationality: false,
  idNumber: false,
  accountType: false,
  accessCode: false,
  termsConsent: false,
  consentedAt: false,
  researchProfile: false,
  researchConsent: false,
  marketingConsent: false,
  internalMarketingSharingConsent: false,
  competitionConsentedAt: false,
  competitionConsented: false,
  parentalConsent: false,
  createdAt: false,
  role: false,
  holland_code: false,
  entrepreneur: false,
  big_5: false,
  understanding_emotion: false,
  perceiving_emotion: false,
  managing_emotion: false,
  rock_paper_scissors: false,
  learning_styles: false,
  maze: false,
  ipip: false,
  interests: false,
  subjectChoices: false,
  exportExcelReport: false
};

const exportOptions = {
  tenantData: {
    title: t("components.ExportUserDialog.dialog_items.personal_information.title"),
    slug: "personalInformation",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.tenant_data.tenant"),
        key: "tenant"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.tenant_data.institution"),
        key: "institution"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.tenant_data.cohort"),
        key: "cohort"
      }
    ]
  },
  personalInformation: {
    title: t("components.ExportUserDialog.dialog_items.personal_information.title"),
    slug: "personalInformation",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.email"),
        key: "email"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.cell_phone_authentication"),
        key: "phone"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.cell_phone_profile"),
        key: "profileContactNumber"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.first_name"),
        key: "firstName"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.last_name"),
        key: "lastName"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.date_of_birth"),
        key: "birthDate"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.id_number"),
        key: "idNumber"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.nationality"),
        key: "nationality"
      },
    ]
  },
  accountSetup: {
    title: "Account Setup",
    slug: "accountSetup",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.account_type"),
        key: "accountType"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.voucher_code"),
        key: "accessCode"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.role"),
        key: "role"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.time_stamps"),
        key: "createdAt"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.personal_information.last_login"),
        key: "lastLogin"
      },
    ]
  },
  choices: {
    title: t("components.ExportUserDialog.dialog_items.choices.title"),
    slug: "choices",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.choices.subject_choices"),
        key: "subjectChoices"
      }
    ]
  },
  research: {
    title: t("components.ExportUserDialog.dialog_items.research.title"),
    slug: "research",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.research.research_profile"),
        key: "researchProfile"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.research.research_consent"),
        key: "researchConsent"
      },
    ]
  },
  consents: {
    title: t("components.ExportUserDialog.dialog_items.consents.title"),
    slug: "consents",
    options: [
      {
        title: t("components.ExportUserDialog.dialog_items.consents.terms_consent"),
        key: "termsConsent"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.competition_consent"),
        key: "competitionConsented"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.parental_consent"),
        key: "parentalConsent"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.marketing_consent"),
        key: "marketingConsent"
      },
      {
        title: t("components.ExportUserDialog.dialog_items.consents.internal_marketing_consent"),
        key: "internalMarketingSharingConsent"
      },
    ]
  },
  reports: {
    holland_code: {
      title: t("components.ExportUserDialog.dialog_items.reports.worker_type"),
      slug: "holland_code",
    },
    entrepreneur: {
      title: t("components.ExportUserDialog.dialog_items.reports.entrepreneur"),
      slug: "entrepreneur",
    },
    big_5: {
      title: t("components.ExportUserDialog.dialog_items.reports.big_5"),
      slug: "big_5",
    },
    learning_styles: {
      title: t("components.ExportUserDialog.dialog_items.reports.learning_styles"),
      slug: "learning_styles",
    },
    perceiving_emotion: {
      title: t("components.ExportUserDialog.dialog_items.reports.perceiving_emotion"),
      slug: "perceiving_emotion",
    },
    managing_emotion: {
      title: t("components.ExportUserDialog.dialog_items.reports.managing_emotion"),
      slug: "managing_emotion",
    },
    understanding_emotion: {
      title: t("components.ExportUserDialog.dialog_items.reports.understanding_emotion"),
      slug: "understanding_emotion",
    },
    rock_paper_scissors: {
      title: t("components.ExportUserDialog.dialog_items.reports.rock_paper_scissors"),
      slug: "rock_paper_scissors",
    },
    maze: {
      title: t("components.ExportUserDialog.dialog_items.reports.maze"),
      slug: "maze",
    }
  },
  researchAssessments: {
    interests: {
      title: t("components.ExportUserDialog.dialog_items.researchAssessments.interests"),
      key: "interests",
    },
    ipip: {
      title: t("components.ExportUserDialog.dialog_items.researchAssessments.ipip"),
      key: "ipip",
    },
  }
};

async function downloadExcel() {
  emit("download", selected.value);
}

</script>
