<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-people-group
    </template>
    <template #header>{{ $t("components.CohortTablePage.title") }}</template>

    <v-data-table-server
      class="mb-6"
      density="compact"
      :loading="store.networkBusy"
      :headers="headers"
      :items="cohorts"
      :items-per-page="perPage"
      :items-length="0"
      @click:row="onClickRow"
      @update:sort-by="onSort"
      :sort-by="sort"
      :hover="true"
      hide-default-footer
    >
      <template v-slot:no-data>
        <h5 class="pa-2 d-flex align-center justify-center align-items-center">
          {{ $t("common.no_data_message") }}
        </h5>
      </template>
      <template v-slot:loader>
        <v-sheet
          class="d-flex align-center justify-center"
          height="100%"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-sheet>
      </template>

      <template v-slot:top>
        <v-toolbar class="data-table-toolbar">
          <search-text-field
            class="ml-2"
            :label="$t('common.search_title')"
            :disabled="store.networkBusy"
            @update:text="text => columns.title.filter = text"

          />
          <v-spacer/>
          <toolbar-menu
            :label="$t('common.filters')"
            :active-filter-count="activeFilterCount"
            icon="far fa-filter"
            @resetFilter="resetFilter"
          >
            <toolbar-menu-list-item-menu
              :label="columns.tenant.title"
              :is-filter-active="!!columns.tenant.filter"
            >
              <tenant-search-dropdown
                v-model="selectedTenant"
              />
              <tenant-institution-single-select-autocomplete
                v-model="selectedInstitution"
                :tenant="selectedTenant"
                class="mb-4"
              />
            </toolbar-menu-list-item-menu>
          </toolbar-menu>
          <v-btn-primary
            v-if="(store.hasAbility(Entity.Institution, Action.read) && store.hasAbility(Entity.Cohort, Action.create)) || isAdminOrRoot"
            prepend-icon="far fa-plus"
            @click="showCreateCohort = true"
            data-pw="createCohortButton"
          >
            {{ $t("buttons.create") }}
          </v-btn-primary>
        </v-toolbar>
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toLocaleDateTime(item["createdAt"]) }}
      </template>

      <template v-slot:bottom>
        <v-divider/>
        <pagination-controls
          v-if="paginatedData"
          class="text-center pt-2"
          :pagination-footer-data="paginationFooterData"
          @update:per-page="onUpdatePerPage"
          @on-next="onNext"
          @on-prev="onPrev"
          @on-page-change="onPageChange"
          data-pw="institutionCohortsPaginationControls"
        />
      </template>
    </v-data-table-server>
    <v-dialog v-model="showCreateCohort" persistent max-width="500">
      <create-tenant-institution-cohort
        :entity-title="$t('components.CreateCards.CreateTenantInstitutionCohort.titleCreateCohort')"
        :entity-type="Entity.Cohort"
        :allowed="store.hasAbility(Entity.Cohort, Action.create) || isAdminOrRoot"
        :empty-cohort="true"
        data-pw="createCohortCard"
        @close="onCloseCreateDialog"
      />
    </v-dialog>
  </authenticated-layout>

</template>

<script setup lang="ts">

import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";

import {onMounted, Ref, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import {
  isYapiError,
  Paginated,
  Cohort,
  YapiError,
  CohortPaginationParams, Entity, Action, Role,
} from "@YenzaCT/sdk";

import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";

import {ColumnsConfig, FooterData, footerDataFactory, usePagination} from "@/lib/pagination";
import PaginationControls from "@/components/PaginationControls.vue";
import SearchTextField from "@/components/SearchTextField.vue";
import {toLocaleDateTime} from "@/lib/time";
import ToolbarMenuListItemMenu from "@/components/DataTable/ToolbarMenuListItemMenu.vue";
import ToolbarMenu from "@/components/DataTable/ToolbarMenu.vue";
import TenantInstitutionSingleSelectAutocomplete
  from "@/components/FormFields/InstitutionSearchDropdown.vue";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import CreateTenantInstitutionCohort from "@/components/CreateCards/CreateTenantInstitutionCohortCard.vue";
const router = useRouter();
const store = useGlobalStore();
const {t} = useI18n();
const route = useRoute();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const showCreateCohort = ref(false);
const cohorts: Ref<Cohort[]> = ref([]);
const paginatedData: Ref<Paginated<Cohort> | undefined> = ref();
let paginationFooterData: FooterData = footerDataFactory();

const cols: ColumnsConfig = {
  id: {
    title: t("components.CohortTablePage.table_headings._id"),
    modelKey: "_id",
    visible: false,
    filter: ""
  },
  title: {
    title: t("components.CohortTablePage.table_headings.title"),
    modelKey: "title",
    visible: true,
    filter: ""
  },
  slug: {
    title: t("components.CohortTablePage.table_headings.slug"),
    modelKey: "slug",
    visible: true,
    filter: ""
  },
  tenant: {
    title: t("components.CohortTablePage.table_headings.tenant"),
    modelKey: "tenant.title",
    visible: true,
    filter: ""
  },
  institution: {
    title: t("components.CohortTablePage.table_headings.institution"),
    modelKey: "institution.title",
    visible: true,
    filter: ""
  },
  createdAt: {
    title: t("components.CohortTablePage.table_headings.created_at"),
    modelKey: "createdAt",
    visible: true,
    filter: ""
  }
};

const {
  columns,
  headers,
  page,
  perPage,
  sort,
  activeFilterCount,
  selectedTenant,
  selectedInstitution,
  createUrlFilterParameters,
  resetFilter,
  onUpdatePerPage,
  onSort,
  onNext,
  onPrev,
  onPageChange,
  setupFooterData,
} = usePagination(fetchPage, {
  initialColumns: cols,
  initialSort: [{
    key: "createdAt",
    order: "asc"
  }]
});

async function fetchPage() {
  const params: CohortPaginationParams = {
    page: page.value,
    size: perPage.value,
    sortBy: sort.value[0].key,
    sortOrder: sort.value[0].order,

    filterTenant: columns.tenant.filter as string || undefined,
    filterInstitution: columns.institution.filter as string || undefined,
  };
  params.title = columns.title.filter as string || undefined;

  try {
    store.networkBusy = true;
    paginatedData.value = (await yapi.admin.cohort.paginate(params)).data;
    await router.push({query: createUrlFilterParameters(params)});

    if (paginatedData.value) {
      cohorts.value = paginatedData.value.docs;
      paginationFooterData = setupFooterData(paginatedData.value);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  setupParams();
  await fetchPage();
});

function setupParams() {
  page.value = route.query.page ? parseInt(route.query.page as string) : page.value;
  perPage.value = route.query.size ? parseInt(route.query.size as string) : perPage.value;
  sort.value[0].key = route.query.sortBy as string || sort.value[0].key;
  sort.value[0].order = route.query.sortOrder as "asc" | "desc" || sort.value[0].order;
  columns.tenant.filter = route.query.filterTenant as string || columns.tenant.filter;
  columns.institution.filter = route.query.filterInstitution as string || columns.institution.filter;
}

const onClickRow = async (event: Event, row: { internalItem: { raw?: Cohort } }) => {
  if (row.internalItem && row.internalItem.raw)
    await openCohortDetails(row.internalItem.raw._id as string);
};

const openCohortDetails = async (id: string) => {
  await router.push({
    name: "cohortDetail",
    params: {id}
  });
};

function onCloseCreateDialog() {
  showCreateCohort.value = false;
  fetchPage();
}
</script>

<style scoped>

</style>
